import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useActionData, useNavigate, useLoaderData, Form, useParams, useOutletContext, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { deleteFile } from '../utilities/api';

import useSnackbar from '../hooks/useSnackbar';

export async function action({ params }) {
    return await deleteFile(params?.id);
}

export default function FileModify() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const status = useActionData();
    const file = useOutletContext();
    const location = useLocation();

    const { id } = useParams();

    const { setSuccess, setWarning } = useSnackbar();

    useEffect(() => {
        if (status !== 200) return;

        setSuccess(t('file_delete_success'));
        navigate(location?.state?.from === 'sidebar' ? '/dashboard' : '/files');
    }, [status]);

    return (
        <Dialog fullWidth maxWidth={'lg'} onClose={() => navigate(location?.state?.from === 'sidebar' ? '/dashboard' : '/files')} open={true}>
            <Form action={location.pathname} method={'POST'}>
                <DialogContent>
                    <Grid>
                        <Grid container justifyContent={'center'} paddingY={'20px'}>
                            <Typography variant={'h5'}>{t('delete_file')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Grid container justifyContent={'center'} paddingY={'20px'} direction={'column'}>
                            <Grid xs={12}>
                                <Typography variant={'h6'}>{t('delete_file_question')}</Typography>
                            </Grid>
                            <Grid xs={12}>
                                <Typography variant={'h6'}>{file?.name}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => navigate(location?.state?.from === 'sidebar' ? '/dashboard' : '/files')} variant={'outlined'}>
                        {t('cancel')}
                    </Button>
                    <Button type={'submit'} color={'error'}>
                        {t('delete')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}
