import { useState } from 'react';
import { Navigate, Outlet, useLocation, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ProtectedRoutesLayout() {
    const { pathname } = useLocation();
    const { user } = useOutletContext();

    return user?.id ? <Outlet context={{ user }} /> : <Navigate replace state={{ from: pathname }} to={'/authentication/login'} />;
}
