import { React, useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Dialog, DialogContent, Grid, Typography, Button, TextField, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { axios } from '../utilities/api';
export default function ClusteringSaveDialog() {
    const { questions, selectedX, data } = useOutletContext();
    const navigate = useNavigate();
    const [saveName, setSaveName] = useState('');
    const [checkedQuestions, setCheckedQuestions] = useState(selectedX);
    const { fileId } = useParams();
    const { t } = useTranslation();

    
    function saveClusterResult() {
        let analysisColumns = [];
        let otherColumns = [];
        Object.entries(questions[0]).forEach((element) => {
            if (selectedX.includes(element[0])) {
                analysisColumns.push({ question: element[0], scaleType: element[1] });
            } else if (checkedQuestions?.includes(element[0])) {
                otherColumns.push({ question: element[0], scaleType: element[1] });
            }
        });

        axios
            .post('/files/cluster-result/', { questions: analysisColumns, others: otherColumns, saveData: data?.save, fileId, fileName: saveName })
            .then((res) => {
                console.log(res.data);
                navigate(-1);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <Dialog open={true}>
            <DialogContent>
                <Grid xs={12} py={1}>
                    <Typography variant={'h6'} align="center">
                        {t('name_of_analysis')}
                    </Typography>
                </Grid>
                <Grid xs={12} py={1}>
                    <TextField value={saveName} onChange={(e) => setSaveName(e.target.value)}></TextField>
                </Grid>
                <FormGroup>
                    {Object.keys(questions[0])?.map(
                        (question, index) =>
                            index !== 0 && (
                                <FormControlLabel
                                    disabled={selectedX?.includes(question)}
                                    onChange={(e) => {
                                        let copy = structuredClone(checkedQuestions);
                                        if (e?.target?.checked) copy.push(question);
                                        else copy = copy?.filter((item) => item !== question);
                                        setCheckedQuestions(copy);
                                    }}
                                    control={<Checkbox checked={checkedQuestions?.includes(question)} />}
                                    label={question?.split('_')?.[1]}
                                />
                            )
                    )}
                </FormGroup>

                <Grid container justifyContent={'space-between'} py={1}>
                    <Button sx={{ width: 'auto' }} color="error" onClick={() => navigate(-1)}>
                        {t('close')}
                    </Button>
                    <Button sx={{ width: 'auto' }} color="success" onClick={() => saveClusterResult()}>
                        {t('save')}
                    </Button>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
