import { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, useActionData, useLocation, useNavigate, useNavigation, useSearchParams } from 'react-router-dom';
import analysis from '../../assets/icons/analysis.png';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { newPassword } from '../../utilities/api';

import useSnackbar from '../../hooks/useSnackbar';

export async function action({ params, request }) {
    const formData = await request.formData();
    const data = { password: formData.get('password'), token: params?.token };
    return await newPassword(data);
}

export default function NewPassword() {
    const { setSuccess } = useSnackbar();

    const { t } = useTranslation();

    const location = useLocation();
    const navigate = useNavigate();
    const navigation = useNavigation();
    const status = useActionData();

    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const passwordInvalid = useMemo(() => (password?.length && passwordConfirm?.length ? password !== passwordConfirm : false), [password, passwordConfirm]);

    useEffect(() => {
        if (status !== 200) return;

        setSuccess(t('password_reset_new_password_success'));
        navigate('/authentication/login');
    }, [status]);

    return (
        <Fragment>
            <Grid container paddingY={1} xs={12} alignItems={'center'} justifyContent={'center'}>
                <Grid xs={1}>
                    <img src={analysis} height={40} />
                </Grid>
                <Grid xs>
                    <Typography align={'center'} variant={'h5'} sx={{ display: 'block', paddingLeft: '10px' }}>
                        {t('password_reset_title')}
                    </Typography>
                </Grid>
                <Grid xs={1}></Grid>
            </Grid>
            <Form action={location.pathname} method={'post'}>
                <Grid paddingY={1} xs={12}>
                    <TextField
                        error={passwordInvalid}
                        helperText={passwordInvalid ? t('passwords_do_not_match') : null}
                        InputProps={{
                            endAdornment: (
                                <IconButton edge={'end'} onClick={() => setPasswordVisible((current) => !current)}>
                                    {passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                            )
                        }}
                        label={t('password')}
                        name={'password'}
                        onChange={(event) => setPassword(event.target.value)}
                        type={passwordVisible ? 'text' : 'password'}
                        value={password}
                    />
                </Grid>
                <Grid paddingY={1} xs={12}>
                    <TextField
                        InputProps={{
                            endAdornment: (
                                <IconButton edge={'end'} onClick={() => setPasswordConfirmVisible((current) => !current)}>
                                    {passwordConfirmVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                            )
                        }}
                        label={t('password_confirm')}
                        onChange={(event) => setPasswordConfirm(event.target.value)}
                        type={passwordConfirmVisible ? 'text' : 'password'}
                        value={passwordConfirm}
                    />
                </Grid>
                <Grid paddingY={1} xs={12}>
                    <input hidden type="text" name="token" value={searchParams.get('token')} />
                    <LoadingButton
                        className="bcolor"
                        disabled={passwordInvalid || !password?.length}
                        loading={navigation.state === 'submitting'}
                        sx={{ color: 'black' }}
                    >
                        {t('password_reset_new_password')}
                    </LoadingButton>
                </Grid>
            </Form>
        </Fragment>
    );
}
