import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useActionData, useNavigate, useLoaderData, useSubmit, useParams, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import useSnackbar from '../hooks/useSnackbar';

export async function action({ params, request }) {
    const formData = await request.formData();
    return await renameFile({ name: formData.get('name') }, params?.id);
}

export default function FileModify() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const status = useActionData();
    const file = useLoaderData();
    const submit = useSubmit();
    const location = useLocation();

    const { id } = useParams();

    const { setSuccess, setWarning } = useSnackbar();
    const [name, setName] = useState(file?.name ?? '');

    useEffect(() => {
        if (status !== 200) return;

        setSuccess(t('file_modify_success'));
        navigate(-1);
    }, [status]);

    return (
        <>
            <Dialog fullWidth maxWidth={'lg'} onClose={() => navigate(-1)} open={true}>
                <DialogContent>
                    <Grid>
                        <Grid container justifyContent={'center'} paddingY={'20px'}>
                            <Typography variant={'h5'}>{t('modify_file')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Grid container justifyContent={'center'} paddingY={'20px'}>
                            <TextField
                                fullWidth
                                label={t('filename')}
                                name={'name'}
                                type={'text'}
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => navigate(-1)} variant={'outlined'}>
                        {t('cancel')}
                    </Button>
                    {location?.state?.from !== 'sidebar' && (
                        <Button
                            onClick={() => {
                                navigate('delete');
                            }}
                            color={'error'}
                        >
                            {t('delete')}
                        </Button>
                    )}

                    <Button
                        onClick={() => {
                            submit({ name: name }, { action: `/files/${id}/edit`, method: 'POST' });
                        }}
                        color={'success'}
                    >
                        {t('modify')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Outlet context={file} />
        </>
    );
}
