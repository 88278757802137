export function sortArrayByProperty(array, property = 'translation_key', descending = false) {
    return descending
        ? array?.sort((a, b) => (b[property] > a[property] ? 1 : b[property] < a[property] ? -1 : 0))
        : array?.sort((a, b) => (b[property] < a[property] ? 1 : b[property] > a[property] ? -1 : 0));
}

export function chartLabelShort(label_string) {
    // Túl hosszú label elcseszi a chat kinézetét
    const words = label_string.split(' ');
    if (words.length > 1) return words[0].length + words[1].length < 10 ? `${words[0]} ${words[1]}..` : `${words[0]}..`;
    else return label_string;
}
