import { Suspense, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

// Layouts
import RootLayout from './layouts/RootLayout';
import AuthenticationLayout from './layouts/AuthenticationLayout';
import ProtectedRoutesLayout from './layouts/ProtectedRoutesLayout';
import SidebarLayout from './layouts/SidebarLayout';
import SurveyTableLayout from './layouts/SurveyTableLayout';

// Routes auth
import Login from './routes/authentication/Login';
import Registration from './routes/authentication/Registration';
import NewPassword from './routes/authentication/NewPassword';
import ResetPassword from './routes/authentication/ResetPassword';

// Routes
import Anova from './routes/Anova';
import Home from './routes/Home';
import Dashboard from './routes/Dashboard';
import Files from './routes/Files';
import FileModify from './routes/FileModify';
import FileUpload from './routes/FileUpload';
import FileDelete, { action as fileDeleteAction } from './routes/FileDelete';
import SurveyTable, { loader as surveyLoader } from './routes/SurveyTable';

import { theme } from './utilities/theme';
import Loading from './components/Loading';
import Error from './components/Error';
import { SnackbarProvider } from './context/SnackbarContext';

import {
    newPasswordAction,
    registrationAction,
    loginAction,
    resetPasswordAction,
    uploadFileAction,
    documentModifyAction,
    scaleTypeChangeAction,
    updateUserAction,
    newPasswordWithConfirm,
    editHeader
} from './utilities/actions';
import { rootLayoutLoader, userFilesLoader, userFileLoader, scaleTypeLoader } from './utilities/loaders';
import DescriptiveStatistics from './components/DescriptiveStatistics';
import CrosstableAnalysis from './components/CrosstableAnalysis';
import DiscriminantAnalysis from './routes/DisriminantAnalysis/DiscriminantAnalysis';
import DAPreconditionTest from './routes/DisriminantAnalysis/DAPreconditionTest';
import DAMulticollinearity from './routes/DisriminantAnalysis/DAMulticollinearity';
import FileUploadConfig from './routes/FileUploadConfig';
import Profile from './routes/Profile';
import Clustering from './routes/Clustering';
import ClusteringSaveDialog from './components/ClusteringSaveDialog';
import PasswordChange from './routes/authentication/PasswordChange';
import Regression from './components/Regression';

const router = createBrowserRouter([
    {
        path: '/',
        element: <RootLayout />,
        loader: rootLayoutLoader,
        shouldRevalidate: (props) => {
            if (props.currentUrl.pathname === '/authentication/login' && props.nextUrl.pathname === '/dashboard') return true;
            if (props.currentUrl.pathname === '/profile') return true;
            if (props.currentUrl.pathname.includes('clustering/save')) return true;
            return false;
        },
        errorElement: <Error />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: 'authentication',
                element: <AuthenticationLayout />,
                children: [
                    { path: 'login', element: <Login />, action: loginAction },
                    { path: 'registration', element: <Registration />, action: registrationAction },
                    { path: 'new-password', element: <NewPassword />, action: newPasswordAction },
                    { path: 'reset-password', element: <ResetPassword />, action: resetPasswordAction }
                ]
            },
            {
                element: <ProtectedRoutesLayout />,
                children: [
                    {
                        element: <SidebarLayout />,
                        loader: userFilesLoader,
                        shouldRevalidate: (props) => {
                            return true;
                        },
                        id: 'sidebar',
                        children: [
                            { path: 'profile', action: updateUserAction, element: <Profile /> },
                            {
                                path: 'passwordchange',
                                action: newPasswordWithConfirm,
                                element: <PasswordChange />
                            },
                            {
                                path: 'dashboard',
                                element: <Dashboard />,
                                children: [
                                    {
                                        path: ':fileId',
                                        element: <SurveyTableLayout />,
                                        children: [
                                            {
                                                path: 'table',
                                                element: <SurveyTable />,
                                                children: [
                                                    { path: 'anova', element: <Anova /> },
                                                    {
                                                        path: 'clustering',
                                                        element: <Clustering />,
                                                        children: [
                                                            {
                                                                path: 'save',
                                                                element: <ClusteringSaveDialog />
                                                            }
                                                        ]
                                                    },
                                                    { path: 'crosstable-analysis', element: <CrosstableAnalysis /> },
                                                    { path: 'descriptive', element: <DescriptiveStatistics /> },
                                                    {
                                                        path: 'discriminant-analysis',
                                                        element: <DiscriminantAnalysis />,
                                                        children: [
                                                            { path: 'preconditions', element: <DAPreconditionTest /> },
                                                            { path: 'multicollinearity', element: <DAMulticollinearity /> }
                                                        ]
                                                    },
                                                    { path: 'regression', element: <Regression /> }
                                                ]
                                            },
                                            { path: 'scale-types', loader: scaleTypeLoader },
                                            { path: 'edit-header', action: editHeader },
                                            { path: 'scale-edit/:scaleId', action: scaleTypeChangeAction },
                                            {
                                                path: 'survey/',
                                                loader: surveyLoader,
                                                shouldRevalidate: (props) => {
                                                    return false;
                                                }
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                path: 'files',
                                element: <Files />,
                                loader: userFilesLoader,
                                children: [
                                    {
                                        path: 'upload',
                                        element: <FileUpload />,
                                        action: uploadFileAction,
                                        children: [{ path: 'config', element: <FileUploadConfig /> }]
                                    },
                                    {
                                        path: ':id',
                                        children: [
                                            { path: 'edit', element: <FileModify />, action: documentModifyAction, loader: userFileLoader },
                                            { path: 'delete', element: <FileDelete />, action: fileDeleteAction, loader: userFileLoader }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    }
]);

export default function App() {
    useEffect(() => {
        document.title = import.meta.env.DEV ? 'STAT Dev' : window.location.hostname.includes('pre') ? 'STAT Pre' : 'STAT';
    }, []);

    return (
        // <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
        <Suspense fallback={<Loading />}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider>
                    <RouterProvider router={router} />
                </SnackbarProvider>
            </ThemeProvider>
        </Suspense>
        // </GoogleOAuthProvider>
    );
}
