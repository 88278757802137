import { useTranslation } from 'react-i18next';
import { useNavigate, useLoaderData, Outlet, Form, useFetcher } from 'react-router-dom';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { IconButton } from '@mui/material';
import useSnackbar from '../hooks/useSnackbar';
import { axios } from '../utilities/api';
import { FormGroup, FormControlLabel, Switch } from '@mui/material';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { useCallback, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

export default function Files() {
    const { t } = useTranslation();
    const { setSuccess, setWarning } = useSnackbar();
    const files = useLoaderData();
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [isVoter, setIsVoter] = useState(false);
    const fetcher = useFetcher();

    const onCellClick = useCallback((event, cellProps) => {
        if (Number(cellProps.columnIndex) !== 3) {
            navigate(`/files/${cellProps?.data?.id}/edit`);
            return;
        } else {
            alert('BETOLTES');
        }
    }, []);

    const columns = [
        { header: '#', name: 'id', defaultFlex: 0.25, minWidth: 50, type: 'number' },
        { header: 'Név', name: 'name', defaultFlex: 1.5, minWidth: 100 },
        { header: 'Feltöltés ideje', name: 'created_at', defaultFlex: 1.5, minWidth: 100, render: ({ value }) => new Date(value).toLocaleString() },
        { header: 'Voter export', name: 'is_voter', defaultFlex: 1.5, minWidth: 100, render: ({ value }) => (value ? 'Igen' : 'Nem') },
        { header: 'Betöltés', name: 'actions', defaultFlex: 0.75, minWidth: 50, render: ({ value, data }) => renderActions({ data }) }
    ];

    function renderActions(params) {
        const id = params?.id;
        const name = params?.name;
        const extension = name?.split('.')[name?.split('.')?.length - 1];
        return (
            <Grid xs={'auto'} sx={{ cursor: 'pointer' }}>
                <IconButton disabled={extension !== 'csv'}>
                    <FileOpenIcon onClick={() => navigate('/dashboard')} />
                </IconButton>
            </Grid>
        );
    }

    return (
        <Grid xs={12}>
            <Grid container justifyContent={'center'} pt={6}>
                <Grid xs={10} md={8}>
                    <Paper elevation={8}>
                        <Grid container>
                            <Grid xs={12} p={2}>
                                <Grid container justifyContent={'space-between'}>
                                    <Grid xs={'auto'}>
                                        <Grid container justifyContent={'flex-start'} direction={'column'}>
                                            <Grid xs={12}>
                                                <Typography variant={'h6'}>{t('documents')}</Typography>
                                            </Grid>
                                            <Grid xs={12}>
                                                <Typography variant={'body1'}>{t('select_row_message')}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid xs={'auto'}>
                                        <Button variant={'contained'} color={'primary'} onClick={() => navigate('/files/upload')}>
                                            {t('upload')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} p={2} sx={{ height: '60vh' }}>
                                <ReactDataGrid
                                    idProperty="id"
                                    columns={columns}
                                    dataSource={files}
                                    pagination
                                    defaultLimit={20}
                                    emptyText={t('no_data_found')}
                                    style={{ minHeight: '100%' }}
                                    onCellClick={onCellClick}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Outlet />
        </Grid>
    );
}
