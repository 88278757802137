import { getUser, getUserFiles, getUserFile, getScaleTypes } from './api';

export async function rootLayoutLoader() {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    if (!accessToken || !refreshToken) return null;
    return await getUser();
}

export async function userFilesLoader() {
    return await getUserFiles();
}

export async function userFileLoader({ params }) {
    return await getUserFile(params?.id);
}

export async function scaleTypeLoader({ params }) {
    return await getScaleTypes(params?.fileId);
}
