import { Navigate, Outlet, useOutletContext } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';

export default function AuthenticationLayout() {
    const accessToken = localStorage?.getItem('accessToken');
    const refreshToken = localStorage?.getItem('refreshToken');

    const { user } = useOutletContext();

    if (accessToken && refreshToken && user?.id) return <Navigate to={'/dashboard'} />;

    return (
        <Grid container justifyContent={'center'} p={2} sx={{ height: '100vh' }}>
            <Grid md={6} sm={10} xl={4} xs={12}>
                <Paper elevation={8}>
                    <Grid xs={12} p={2}>
                        <Outlet />
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}
