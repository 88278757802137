import { useState, React } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button, IconButton, Switch, Dialog, DialogContent } from '@mui/material';
import { useOutletContext } from 'react-router';
import { useTranslation } from 'react-i18next';
import { axios } from '../../utilities/api';
import { Droppable, DragDropContext, Draggable } from '@hello-pangea/dnd';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import QuestionsDraggable from '../../components/QuestionsDraggable';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Plot from 'react-plotly.js';
import HelpIcon from '@mui/icons-material/Help';
import error_icon from '../../assets/icons/error.png';
import correct_icon from '../../assets/icons/correct.png';
import warning_icon from '../../assets/icons/warning.png';
import useSnackbar from '../../hooks/useSnackbar';
const ErrorIcon = () => <img src={error_icon} alt="error" width="25" height="25" />;
const CorrectIcon = () => <img src={correct_icon} alt="correct" width="25" height="25" />;
const WarningIcon = () => <img src={warning_icon} alt="warning" width="25" height="25" />;

export default function DAPreconditionTest() {
    const { questions, user } = useOutletContext();
    const { t } = useTranslation();
    const [selectedX, setSelectedX] = useState([]);
    const [selectedY, setSelectedY] = useState();
    const [data, setData] = useState(null);
    const [dataLabel, setDataLabel] = useState(false);
    const [infoDialog, setInfoDialog] = useState(false);
    // Ordinal cant be used in discriminant analysis
    const unOrdinals = questions?.filter((question) => question?.scale_type !== 'ordinal');
    const { setWarning } = useSnackbar();
    async function loadPreCheckData() {
        try {
            let copy = structuredClone(selectedX);
            const data = { question_x: copy?.map((item) => item?.split('_')[0]), question_y: selectedY?.split('_')[0] };
            const response = await axios.post('analysis/discriminant-analysis-check/', data);
            const tooltipLimit = 25;
            const frequencies = JSON.parse(response?.data?.value_pairs_by_columns)?.[0]?.frequencies;
            console.log(JSON.parse(response?.data?.value_pairs_by_columns));
            if (frequencies?.length > tooltipLimit && dataLabel) setDataLabel(false);
            else if (frequencies?.length <= tooltipLimit && !dataLabel) setDataLabel(true);
            setData(response.data);
        } catch (error) {
            console.log(error);
            setWarning(t('query_not_successful'));
            throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 833 };
        }
    }

    function loadEmblem(value) {
        if (value === null) return <WarningIcon />;
        else if (value) return <CorrectIcon />;
        else if (!value) return <ErrorIcon />;
    }

    return (
        <>
            <Dialog open={infoDialog}>
                <DialogContent>
                    <Grid container justifyContent={'center'}>
                        <Typography align="center">{infoDialog}</Typography>
                    </Grid>
                    <Grid container justifyContent={'center'} pt={2}>
                        <Button sx={{ width: 'auto' }} onClick={() => setInfoDialog(false)}>
                            {t('close')}
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
            <DragDropContext
                onDragEnd={(event) => {
                    if (event.source.droppableId === event.destination?.droppableId) return;

                    if (event.source.droppableId === 'questions' && event.destination?.droppableId === 'selectedX') {
                        if (selectedX?.includes(event.draggableId)) return;
                        let temp = [...selectedX];
                        temp.push(event.draggableId);
                        setSelectedX(temp);
                    }

                    if (event.source.droppableId === 'questions' && event.destination?.droppableId === 'selectedY') {
                        setSelectedY(event.draggableId);
                    }
                }}
            >
                <Grid container>
                    <Grid xs={12} py={2}>
                        <QuestionsDraggable questions={unOrdinals} />
                    </Grid>
                    {user?.details && (
                        <Typography variant="body2" align="center">
                            {t('dep_indep_help_text')}
                        </Typography>
                    )}

                    <Grid xs={12} pt={2}>
                        <Typography variant="body1">
                            {t('discriminant_x')}{' '}
                            {!user?.details && (
                                <IconButton onClick={() => setInfoDialog(t('dep_indep_help_text'))}>
                                    <HelpIcon />
                                </IconButton>
                            )}
                        </Typography>
                        <Droppable droppableId="selectedX">
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    style={{
                                        backgroundColor: snapshot.isDraggingOver ? '#4C9F93' : '#cbe6e2',
                                        width: '100%',
                                        height: '100%'
                                    }}
                                    {...provided.droppableProps}
                                >
                                    {selectedX?.map((question, index) => {
                                        return (
                                            <Grid key={index} container justifyContent="center">
                                                <Grid xs={10}>{question?.substring(question?.indexOf('_') + 1)}</Grid>
                                                <Grid xs={2}>
                                                    <IconButton
                                                        onClick={() => {
                                                            let temp = [...selectedX];
                                                            temp.splice(index, 1);
                                                            setSelectedX(temp);
                                                        }}
                                                    >
                                                        <HighlightOffIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </Grid>
                    <Grid xs={12} py={6}>
                        <Typography variant="body1">
                            {t('discriminant_y')}
                            {!user?.details && (
                                <IconButton onClick={() => setInfoDialog(t('dep_indep_help_text'))}>
                                    <HelpIcon />
                                </IconButton>
                            )}
                        </Typography>
                        <Droppable droppableId="selectedY">
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    style={{
                                        backgroundColor: snapshot.isDraggingOver ? '#4C9F93' : '#cbe6e2',
                                        width: '100%',
                                        height: '100%'
                                    }}
                                    {...provided.droppableProps}
                                >
                                    {selectedY && (
                                        <Grid container>
                                            <Grid xs={10}> {selectedY?.substring(selectedY?.indexOf('_') + 1) ?? t('selectedy')}</Grid>
                                            <Grid xs={2}>
                                                <IconButton
                                                    onClick={() => {
                                                        setSelectedY(undefined);
                                                    }}
                                                >
                                                    <HighlightOffIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    )}

                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </Grid>
                </Grid>
                <Button disabled={!selectedX?.length || !selectedY} onClick={() => loadPreCheckData()}>
                    {t('check')}
                </Button>

                {data && (
                    <>
                        {/* PRECONDITION RESULTS */}

                        <Grid container justifyContent={'flex-start'} pt={5}>
                            <Grid xs={12} py={1}>
                                <Grid container alignItems={'center'}>
                                    <Typography variant={'body1'} pr={1}>
                                        {t('measurement_level_of_variables')}
                                    </Typography>
                                    {loadEmblem(data?.['variable_types']?.['x_metric'] && data?.['variable_types']?.['y_nominal'])}
                                </Grid>
                                <Grid pl={4} container alignItems={'center'}>
                                    <Typography variant={'body2'} pr={1}>
                                        {t('x_metric')}
                                    </Typography>
                                    {loadEmblem(data?.['variable_types']?.['x_metric'])}
                                </Grid>
                                <Grid pl={4} container alignItems={'center'}>
                                    <Typography variant={'body2'} pr={1}>
                                        {t('y_nominal')}
                                    </Typography>
                                    {loadEmblem(data?.['variable_types']?.['y_nominal'])}
                                </Grid>
                            </Grid>

                            <Grid xs={12} py={1}>
                                <Grid container alignItems={'center'}>
                                    <Typography variant={'body1'} pr={1}>
                                        {t('independence_of_data')}
                                    </Typography>
                                    {loadEmblem(data?.['independence']?.['no_correlation'])}
                                </Grid>
                                <Grid container alignItems={'center'}>
                                    <Typography variant={'body2'} pl={4} pr={1}>
                                        {t('independence_of_data_desc')}
                                    </Typography>
                                </Grid>
                                <Grid container justifyContent={'center'}>
                                    {Boolean(data?.independence?.correlation_matrix) ? (
                                        <Plot data={[data?.independence?.correlation_matrix]} />
                                    ) : (
                                        <Typography variant="body2">{t('no_data')}</Typography>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid xs={12} py={1}>
                                <Grid container alignItems={'center'}>
                                    <Typography variant={'body1'} pr={1}>
                                        {t('group_exclusivity')}
                                    </Typography>
                                    {loadEmblem(data?.['no_overlap'])}
                                </Grid>
                                <Grid container alignItems={'center'}>
                                    <Typography variant={'body2'} pl={4} pr={1}>
                                        {t('group_exclusivity_desc')}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid xs={12} py={1}>
                                <Grid container alignItems={'center'}>
                                    <Typography variant={'body1'} pr={1}>
                                        {t('similar_group_size')}
                                    </Typography>
                                    {loadEmblem(data?.['similar_group_size'])}
                                </Grid>

                                <Grid container alignItems={'center'}>
                                    <Typography variant={'body2'} pl={4} pr={1}>
                                        {t('similar_group_size_desc')}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid xs={12} py={1}>
                                <Grid container alignItems={'center'}>
                                    <Typography variant={'body1'} pr={1}>
                                        {t('sample_size')}
                                    </Typography>
                                    {loadEmblem(data?.['samples_variables_10times'] && data?.['less_independent_than_mincount'])}
                                </Grid>
                                <Grid container alignItems={'center'}>
                                    <Typography variant={'body2'} pl={4} pr={1}>
                                        {t('sample_size_desc_mincount')}
                                    </Typography>
                                    {loadEmblem(data?.['less_independent_than_mincount'])}
                                </Grid>
                                <Grid container alignItems={'center'}>
                                    <Typography variant={'body2'} pl={4} pr={1}>
                                        {t('sample_size_desc_10times')}
                                    </Typography>
                                    {loadEmblem(data?.['samples_variables_10times'])}
                                </Grid>
                            </Grid>

                            <Grid xs={12} py={1}>
                                <Grid container alignItems={'center'}>
                                    <Typography variant={'body1'} pr={1}>
                                        {t('linearity')}
                                    </Typography>
                                    {/* {loadEmblem(data?.['follows_normal_distribution'])} */}
                                </Grid>
                                <Grid container alignItems={'center'}>
                                    <Typography variant={'body2'} pl={4} pr={1}>
                                        {t('linearity_desc')}
                                    </Typography>
                                </Grid>

                                <Grid container justifyContent={'flex-start'} pt={2}>
                                    <Grid xs={12}>
                                        <Typography align="center">
                                            {t('data_label')}
                                            <Switch checked={dataLabel} onChange={() => setDataLabel((prev) => !prev)} />
                                        </Typography>
                                        {Boolean(data?.value_pairs_by_columns) ? (
                                            <Grid container justifyContent={'center'}>
                                                {JSON.parse(data?.value_pairs_by_columns)?.map((plot) => {
                                                    return (
                                                        <Plot
                                                            data={[
                                                                {
                                                                    x: plot?.crosstab_x,
                                                                    y: plot?.crosstab_y,
                                                                    text: plot['frequencies'],
                                                                    textposition: 'top center',
                                                                    type: 'scatter',
                                                                    mode: dataLabel ? 'markers+text' : 'markers',
                                                                    marker: { color: 'red' }
                                                                },
                                                                {
                                                                    x: [plot?.x_min, plot?.x_max],
                                                                    y: [plot?.y_min, plot?.y_max],
                                                                    type: 'scatter',
                                                                    mode: 'lines',
                                                                    marker: { color: 'blue' }
                                                                }
                                                            ]}
                                                            layout={{
                                                                xaxis: {
                                                                    title: `${plot?.x_axis.length > 50 ? '...' : ''}${plot?.x_axis.substr(
                                                                        plot?.x_axis.length - 50
                                                                    )}`
                                                                },
                                                                yaxis: {
                                                                    title: `${plot?.y_axis.length > 50 ? '...' : ''}${plot?.y_axis.substr(
                                                                        plot?.y_axis.length - 50
                                                                    )}`
                                                                },
                                                                showlegend: false
                                                            }}
                                                        />
                                                    );
                                                })}
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid xs={12} py={1}>
                                <Grid container alignItems={'center'}>
                                    <Typography variant={'body1'} pr={1}>
                                        {t('normality')}
                                    </Typography>
                                    {loadEmblem(data?.['follows_normal_distribution'])}
                                </Grid>
                                <Grid container alignItems={'center'}>
                                    <Typography variant={'body2'} pl={4} pr={1}>
                                        {t('normality_desc')}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid xs={12} py={1}>
                                <Grid container alignItems={'center'}>
                                    <Typography variant={'body1'} pr={1}>
                                        {t('variance_homogeneity', { serialNumber: 8})}
                                    </Typography>
                                    {loadEmblem(data?.['variance_homogeneity'])}
                                </Grid>
                                <Grid container alignItems={'center'}>
                                    <Typography variant={'body2'} pl={4} pr={1}>
                                        {t('variance_homogeneity_desc')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </DragDropContext>
        </>
    );
}
