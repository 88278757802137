import { useTranslation } from 'react-i18next';
import { Outlet, useOutletContext } from 'react-router-dom';
import { React, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { Dialog, DialogContent, IconButton, Button } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

export default function DiscriminantAnalysis() {
    const { t } = useTranslation();
    const { questions, user } = useOutletContext();
    const [infoDialog, setInfoDialog] = useState(false);
    return (
        <Grid container spacing={2} py={1}>
            {/* <DragDropContext
        onDragEnd={(event) => {
          console.log(event);
          if (event.source.droppableId === 'questions' && event.destination?.droppableId === 'questions') return;
          if (event.source.droppableId === 'questions' && event.destination?.droppableId === 'selectedX') return;
          // if (event.source.droppableId === 'questionColumn' && event.destination?.droppableId === 'questionColumn') return;
          // if (event.source.droppableId === 'questionRow' && event.destination?.droppableId === 'questionRow') return;

          // if (event.source.droppableId === 'questions' && event.destination?.droppableId === 'questionColumn') {
          //   setQuestionColumn(event.draggableId);
          // }

          // if (event.source.droppableId === 'questions' && event.destination?.droppableId === 'questionRow') {
          //   setQuestionRow(event.draggableId);
          // }
        }}
      > */}
            <Dialog open={infoDialog}>
                <DialogContent>
                    <Grid container justifyContent={'center'}>
                        <Typography align="center">{infoDialog}</Typography>
                    </Grid>
                    <Grid container justifyContent={'center'} pt={2}>
                        <Button sx={{ width: 'auto' }} onClick={() => setInfoDialog(false)}>
                            {t('close')}
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Grid item xs={12}>
                <Typography variant="h5" align="center">
                    {t('discriminant_analysis')}
                    {!user?.details && (
                        <IconButton onClick={() => setInfoDialog(t('da_help_text'))}>
                            <HelpIcon />
                        </IconButton>
                    )}
                </Typography>
                {user?.details && (
                    <Grid container justifyContent={'center'}>
                        <Typography variant={'body2'} align="center">
                            {t('da_help_text')}
                        </Typography>
                    </Grid>
                )}
            </Grid>

            <Grid item xs={12}>
                <Outlet context={{ questions, user }} />
            </Grid>
            {/* </DragDropContext> */}
        </Grid>
    );
}
