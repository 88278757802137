import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useActionData, useNavigate, useNavigation, Form, useParams, useOutletContext, useFetcher } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { deleteFile } from '../utilities/api';
import { Checkbox, FormControlLabel } from '@mui/material';
import useSnackbar from '../hooks/useSnackbar';

export async function action({ params }) {
    return await deleteFile(params?.id);
}

export default function FileModify() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const navigation = useNavigation();
    const status = useActionData();

    const [file, setFile] = useState();
    const [isVoter, setIsVoter] = useState(false);
    const { setSuccess, setWarning } = useSnackbar();

    const fetcher = useFetcher();

    useEffect(() => {
        // if (fetcher?.data) navigate(`/dashboard/${fetcher?.data}/table`);
        if (fetcher?.data) navigate('config');
    }, [fetcher?.data]);


    async function uploadAction(e) {
        e.preventDefault();
        fetcher.submit({ file: file, isVoter: isVoter }, { action: location.pathname, method: 'post' });
    }

    return (
        <>
            <Dialog fullWidth maxWidth={'lg'} open={true}>
                <fetcher.Form action={uploadAction} encType={'multipart/form-data'} method={'post'}>
                    <DialogContent>
                        <Grid>
                            <Grid container justifyContent={'center'} paddingY={'20px'}>
                                <Typography variant={'h5'}>{t('upload_file')}</Typography>
                            </Grid>
                        </Grid>
                        <input value={isVoter} name={'isVoter'} hidden />
                        <Grid container justifyContent={'space-evenly'}>
                            <Button component={'label'} sx={{ width: 'auto' }}>
                                {file ? file.name : t('browse')}
                                <input
                                    name={'file'}
                                    hidden
                                    onChange={(event) => {
                                        setFile(event.target.files?.[0]);
                                    }}
                                    type={'file'}
                                />
                            </Button>
                            <FormControlLabel
                                label={t('voter_import')}
                                control={<Checkbox checked={isVoter} />}
                                onChange={(event) => setIsVoter(event.target.checked)}
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => navigate('/files')} variant={'outlined'}>
                            {t('cancel')}
                        </Button>
                        <LoadingButton disabled={!Boolean(file)} loading={fetcher.state === 'submitting'} type={'submit'} variant={'contained'} color="primary">
                            {t('upload')}
                        </LoadingButton>
                    </DialogActions>
                </fetcher.Form>
            </Dialog>
            <Outlet context={{ fetcher, file, isVoter }} />
        </>
    );
}
