import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, useActionData, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import analysis from '../../assets/icons/analysis.png';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { resetPassword } from '../../utilities/api';

import Link from '../../components/Link';
import useSnackbar from '../../hooks/useSnackbar';

export async function action({ request }) {
    const formData = await request.formData();
    const data = { email: formData.get('email') };
    return await resetPassword(data);
}

export default function ResetPassword() {
    const { setSuccess } = useSnackbar();

    const { t } = useTranslation();

    const location = useLocation();
    const navigate = useNavigate();
    const navigation = useNavigation();
    const status = useActionData();

    useEffect(() => {
        if (status !== 200) return;

        setSuccess(t('password_reset_success'));
        navigate('/authentication/login');
    }, [status]);

    return (
        <Fragment>
            <Grid container paddingY={1} xs={12} alignItems={'center'} justifyContent={'center'}>
                <Grid xs={1}>
                    <img src={analysis} height={40} />
                </Grid>
                <Grid xs>
                    <Typography align={'center'} variant={'h5'} sx={{ display: 'block', paddingLeft: '10px' }}>
                        {t('password_reset_title')}
                    </Typography>
                </Grid>
                <Grid xs={1}></Grid>
            </Grid>
            <Form action={location.pathname} method={'post'}>
                <Grid paddingY={1} xs={12}>
                    <TextField autoFocus label={t('email_address')} name={'email'} />
                </Grid>
                <Grid paddingY={1} xs={12}>
                    <LoadingButton className="bcolor" sx={{ color: 'black' }} loading={navigation.state === 'submitting'}>
                        {t('password_reset_submit')}
                    </LoadingButton>
                </Grid>
            </Form>
            <Grid pt={1} xs={12} sx={{ m: '0 auto', textAlign: 'center' }}>
                <Link sx={{ width: 1, p: 0, color: 'black', display: 'flex' }} to={'/authentication/login'}>
                    <ArrowBackIcon sx={{ width: '20px', mr: 1 }} /> {t('button_text_back')}
                </Link>
            </Grid>
        </Fragment>
    );
}
