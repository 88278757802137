import { useState, useMemo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Outlet, useOutletContext, useLoaderData, Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';

import useLogout from '../hooks/useLogout';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import dash_icon from '../assets/icons/dashboard.png';
import files_icon from '../assets/icons/folder.png';
import logout_icon from '../assets/icons/logout.png';
import edit_icon from '../assets/icons/edit.png';
import upload_icon from '../assets/icons/upload.png';
import delete_icon from '../assets/icons/delete.png';
import load_icon from '../assets/icons/openfolder.png';
import user_icon from '../assets/icons/user.png';
import { IconButton, Typography } from '@mui/material';

const LargeIconSize = 25;
const SmallIconSize = 22;

const DashboardIcon = () => <img src={dash_icon} alt="Dashboard" style={{ width: `${LargeIconSize}px`, height: `${LargeIconSize}px` }} />;
const FilesIcon = () => <img src={files_icon} alt="Files" style={{ width: `${LargeIconSize}px`, height: `${LargeIconSize}px` }} />;
const LogoutIcon = () => <img src={logout_icon} alt="Logout" style={{ width: `${LargeIconSize}px`, height: `${LargeIconSize}px` }} />;
const UploadIcon = () => <img src={upload_icon} alt="Upload" style={{ width: `${LargeIconSize}px`, height: `${LargeIconSize}px` }} />;
const LoadIcon = () => <img src={load_icon} alt="Load" style={{ width: `${LargeIconSize}px`, height: `${LargeIconSize}px` }} />;

const EditIcon = () => <img src={edit_icon} alt="Edit" style={{ width: `${SmallIconSize}px`, height: `${SmallIconSize}px` }} />;
const DeleteIcon = () => <img src={delete_icon} alt="Delete" style={{ width: `${SmallIconSize}px`, height: `${SmallIconSize}px` }} />;
const UserIcon = () => <img src={user_icon} alt="User" style={{ width: `${SmallIconSize}px`, height: `${SmallIconSize}px` }} />;

const width = 300;

export default function SidebarLayout() {
    const files = useLoaderData();
    const logout = useLogout();
    const navigate = useNavigate();

    const { user } = useOutletContext();
    const { t } = useTranslation();

    const [collapsed, setCollapsed] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(width);

    useEffect(() => {
        if (collapsed) {
            setSidebarWidth(80);
        } else {
            setSidebarWidth(width);
        }
    }, [collapsed]);

    const fileNameLimit = useMemo(() => {
        return collapsed ? 15 : 17;
    }, [collapsed]);

    function isCurrentFileSelected(file) {
        return location.pathname.includes('dashboard') && location.pathname?.split('/').includes(String(file.id));
    }

    const submenuStyle = { backgroundColor: '#80C992' };
    console.log(files);
    return (
        <Grid container style={{ display: 'flex', height: '100vh' }}>
            <Sidebar
                backgroundColor={submenuStyle.backgroundColor}
                width={`${sidebarWidth}px`}
                rootStyles={{ borderRight: '0px', color: '#000', fontSize: '.9rem' }}
                collapsed={collapsed}
                onCollapse={() => setCollapsed(!collapsed)}
                collapsedWidth="80px"
                transitionDuration={0}
            >
                <Menu
                    menuItemStyles={{
                        button: {
                            backgroundColor: submenuStyle.backgroundColor,
                            '&:hover': {
                                // backgroundColor: submenuStyle.backgroundColor
                                backgroundColor: '#70bfb2'
                            }
                        }
                    }}
                >
                    <MenuItem icon={collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />} onClick={() => setCollapsed(!collapsed)}></MenuItem>
                    <MenuItem icon={<UserIcon />} component={<Link to={'/profile'} />}>
                        {t('profile')}
                    </MenuItem>
                    <MenuItem icon={<DashboardIcon />} component={<Link to={'/dashboard'} />}>
                        {t('dashboard')}
                    </MenuItem>
                    <SubMenu label={t('files')} defaultOpen={true} icon={<FilesIcon />}>
                        <MenuItem icon={<UploadIcon />} component={<Link to={'/files/upload'} />}>
                            {t('upload')}
                        </MenuItem>
                        <SubMenu label={t('load')} icon={<LoadIcon />} defaultOpen={true}>
                            {files.map(
                                (file) =>
                                    file?.table_type === 'file' && (
                                        <MenuItem
                                            key={file.id}
                                            onClick={(event) => {
                                                if (event.target?.alt === 'Edit') {
                                                    navigate(`/files/${file?.id}/edit`, { state: { from: 'sidebar' } });
                                                    return;
                                                } else if (event.target?.alt === 'Delete') {
                                                    navigate(`/files/${file?.id}/delete`, { state: { from: 'sidebar' } });
                                                    return;
                                                }
                                                navigate(`/dashboard/${file?.id}/table`);
                                            }}
                                            style={{ backgroundColor: isCurrentFileSelected(file) ? '#70bfb2' : '' }}
                                        >
                                            <Grid container justifyContent="space-between" alignItems={'center'}>
                                                <Grid item sx={{ display: 'block' }}>
                                                    <Typography variant="subtitle">
                                                        {file.name.substring(0, fileNameLimit) + (file.name.length > fileNameLimit ? '...' : '')}
                                                    </Typography>
                                                </Grid>
                                                {!collapsed && (
                                                    <Grid item xs={'auto'}>
                                                        <IconButton size="small">
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton size="small">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </MenuItem>
                                    )
                            )}
                        </SubMenu>
                    </SubMenu>

                    <SubMenu label={t('analysis')} defaultOpen={false} icon={<FilesIcon />}>
                        {files.map(
                            (file) =>
                                file?.table_type !== 'file' && (
                                    <MenuItem
                                        key={file.id}
                                        onClick={(event) => {
                                            if (event.target?.alt === 'Edit') {
                                                navigate(`/files/${file?.id}/edit`, { state: { from: 'sidebar' } });
                                                return;
                                            } else if (event.target?.alt === 'Delete') {
                                                navigate(`/files/${file?.id}/delete`, { state: { from: 'sidebar' } });
                                                return;
                                            }
                                            navigate(`/dashboard/${file?.id}/table`);
                                        }}
                                        style={{ backgroundColor: isCurrentFileSelected(file) ? '#70bfb2' : '' }}
                                    >
                                        <Grid container justifyContent="space-between" alignItems={'center'}>
                                            <Grid item sx={{ display: 'block' }}>
                                                <Typography variant="subtitle">
                                                    {file.name.substring(0, fileNameLimit) + (file.name.length > fileNameLimit ? '...' : '')}
                                                </Typography>
                                            </Grid>
                                            {!collapsed && (
                                                <Grid item xs={'auto'}>
                                                    <IconButton size="small">
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton size="small">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </MenuItem>
                                )
                        )}
                    </SubMenu>
                    <MenuItem icon={<LogoutIcon />} onClick={() => logout()}>
                        {t('logout')}
                    </MenuItem>
                </Menu>
            </Sidebar>
            <Grid container sx={{ width: `calc(100% - ${sidebarWidth}px)` }}>
                <Grid xs={12}>
                    <Grid container justifyContent="center" style={{ height: '100%' }}>
                        <Outlet context={{ user, files, sidebarWidth }} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
