import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, useActionData, useLocation, useNavigate, useNavigation, useSubmit, useOutletContext } from 'react-router-dom';
import { LoginSocialGoogle } from 'reactjs-social-login';
import { GoogleLoginButton } from 'react-social-login-buttons';
import analysis from '../../assets/icons/analysis.png';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';

import Link from '../../components/Link';
import useSnackbar from '../../hooks/useSnackbar';

export default function Login() {
    const { setError, setSuccess } = useSnackbar();
    const { t } = useTranslation();

    const navigate = useNavigate();
    const navigation = useNavigation();
    const { pathname, state } = useLocation();
    const response = useActionData();
    const submit = useSubmit();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [visible, setVisible] = useState(false);

    // const [isSocialLogin, setIsSocialLogin] = useState(false);

    useEffect(() => {
        if (!response?.status) return;
        if (response?.status === 200) {
            navigate('/dashboard');
        } else if (response?.status === 401) setError(t('login_error_401'));
        else setError('unknown_error');
    }, [JSON.stringify(response)]);

    return (
        <Fragment>
            <Grid container paddingY={1} xs={12} alignItems={'center'} justifyContent={'center'}>
                <Grid xs={1}>
                    <img src={analysis} height={40} />
                </Grid>
                <Grid xs>
                    <Typography align={'center'} variant={'h5'} sx={{ display: 'block', paddingLeft: '10px' }}>
                        {t('login')}
                    </Typography>
                </Grid>
                <Grid xs={1}></Grid>
            </Grid>
            <Form action={pathname} method={'post'}>
                <Grid paddingY={1} xs={12}>
                    <TextField autoFocus label={t('email_address')} name={'email'} onChange={(event) => setEmail(event.target.value)} value={email} />
                </Grid>
                <Grid paddingY={1} xs={12}>
                    <TextField
                        InputProps={{
                            endAdornment: (
                                <IconButton edge={'end'} onClick={() => setVisible((current) => !current)}>
                                    {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                            )
                        }}
                        label={t('password')}
                        name={'password'}
                        onChange={(event) => setPassword(event.target.value)}
                        type={visible ? 'text' : 'password'}
                        value={password}
                    />
                </Grid>
                <Grid paddingY={1} xs={12}>
                    <LoadingButton
                        className="bcolor"
                        id={'loginBtn'}
                        disabled={!email?.length || !password?.length}
                        loading={navigation.state === 'submitting'}
                        sx={{ color: 'black' }}
                    >
                        {t('login')}
                    </LoadingButton>
                </Grid>
                {/* <Grid pb={2} xs={12} sx={{ textAlign: 'center' }}>
                    <Link sx={{ display: 'block', width: 1, color: 'black' }} to={'/authentication/reset-password'}>
                        {t('password_reset_link_text')}
                    </Link>
                </Grid> */}
            </Form>
            <Grid mt={1} xs={12}>
                <Grid xs={12}>
                    <LoginSocialGoogle
                        client_id={import.meta.env.VITE_GOOGLE_CLIENT_ID}
                        onResolve={(response) => {
                            const formData = new FormData();
                            formData.append('isSocialLogin', true);
                            formData.append('token', response?.data?.access_token);
                            formData.append('provider', 'google-oauth2');
                            submit(formData, { action: location.pathname, method: 'POST' });
                        }}
                        onReject={(error) => console.warn(error)}
                        scope={'https://www.googleapis.com/auth/userinfo.email'}
                    >
                        <GoogleLoginButton>
                            <span style={{ color: 'grey' }}>{t('continue_with_google')}</span>
                        </GoogleLoginButton>
                    </LoginSocialGoogle>
                </Grid>
                <Button sx={{ my: 1, p: 0 }}>
                    <Link
                        sx={{ display: 'block', width: 1, color: 'black' }}
                        state={state?.from ?? undefined}
                        to={'/authentication/registration'}
                        className="bcolor"
                    >
                        {t('registration')}
                    </Link>
                </Button>
                <Grid xs={12} sx={{ textAlign: 'center' }}>
                    <Link sx={{ display: 'block', width: 1, color: 'black', backgroundColor: 'lightgray' }} to={'/authentication/reset-password'}>
                        {t('password_reset_link_text')}
                    </Link>
                </Grid>
            </Grid>
        </Fragment>
    );
}
