import React, { useEffect } from 'react';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { Form, useActionData, useLocation, useNavigate, useNavigation, useSubmit, useOutletContext, useFetcher } from 'react-router-dom';
import { Paper, TextField, Select, MenuItem, Checkbox, Snackbar } from '@mui/material';
import analysis from '../../assets/icons/analysis.png';
import { Typography, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import useSnackbar from '../../hooks/useSnackbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Link from '../../components/Link';

export default function PasswordChange(props) {
    const pathname = useLocation();
    const { t } = useTranslation();
    const resp = useActionData();
    const { setSuccess, setWarning } = useSnackbar();
    useEffect(() => {
        if (resp?.status === 200) setSuccess(t('password_new_success'));
        else if (resp?.status >= 400) setWarning(t('password_new_fail'));
    }, [resp]);
    return (
        <Grid xs={12} md={6} p={1}>
            <Paper elevation={8}>
                <Form action={pathname} method="post">
                    <Grid container p={1} spacing={1} justifyContent={'center'}>
                        <Grid xs={12}>
                            <Grid container justifyContent={'center'}>
                                <Grid xs={1}>
                                    <img src={analysis} height={40} />
                                </Grid>
                                <Grid xs>
                                    <Typography align={'center'} variant={'h5'} sx={{ display: 'block' }}>
                                        {t('password_change')}
                                    </Typography>
                                </Grid>
                                <Grid xs={1}></Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12}>
                            <TextField type="password" name="current_password" fullWidth label={t('password_current')} />
                        </Grid>
                        <Grid xs={12}>
                            <TextField type="password" name="new_password" fullWidth label={t('password_new')} />
                        </Grid>
                        <Grid xs={12}>
                            <TextField type="password" name="new_password_confirm" fullWidth label={t('password_new_again')} />
                        </Grid>

                        <Grid paddingY={1} xs={12}>
                            <LoadingButton
                                className="bcolor"
                                // disabled={!email?.length || !password?.length}
                                // loading={navigation.state === 'submitting'}
                                sx={{ color: 'black' }}
                            >
                                {t('save')}
                            </LoadingButton>
                        </Grid>
                        <Grid pt={1} xs={12} sx={{ m: '0 auto', textAlign: 'center' }}>
                            <Link sx={{ width: 1, p: 0, color: 'black', display: 'flex' }} to={'/profile'}>
                                <ArrowBackIcon sx={{ width: '20px', mr: 1 }} /> {t('button_text_back')}
                            </Link>
                        </Grid>
                    </Grid>
                </Form>
            </Paper>
        </Grid>
    );
}
