import { Paper, Divider, List, ListItem, ListItemText } from '@mui/material';
import { Droppable, Draggable } from '@hello-pangea/dnd';

export default function QuestionsDraggable({ questions, height = '30vh' }) {
    return (
        <Paper style={{ height: height }}>
            <Droppable droppableId="questions">
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        style={{
                            backgroundColor: 'white',
                            width: '100%',
                            height: '100%',
                            overflow: 'auto'
                        }}
                        {...provided.droppableProps}
                    >
                        <List dense disablePadding style={{ display: 'flex', flexFlow: 'column wrap', gap: '0 30px' }}>
                            {Object.keys(questions?.[0] ?? {})?.map((question, index) => {
                                let name = question.split('_');
                                return (
                                    <Draggable key={index} draggableId={question} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{
                                                    backgroundColor: snapshot.isDragging ? '#4C9F93' : 'white',
                                                    borderRadius: '4px',
                                                    paddingLeft: 12,
                                                    ...provided.draggableProps.style
                                                }}
                                            >
                                                <ListItem disablePadding sx={{ width: 'auto' }}>
                                                    {/* {console.log(question)} */}
                                                    <ListItemText primary={name[1]} secondary={questions[0]?.[question]} />
                                                </ListItem>
                                                <Divider />
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                        </List>
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </Paper>
    );
}
