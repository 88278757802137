export const colors = [
    '#FF0000',
    '#00FF00',
    '#0000FF',
    '#FFA500',
    '#FFFF00',
    '#800080',
    '#FFC0CB',
    '#008080',
    '#FF00FF',
    '#808080',
    '#800000',
    '#008000',
    '#000080',
    '#FF6347',
    '#FFFFE0',
    '#A52A2A',
    '#7CFC00',
    '#F08080',
    '#6A5ACD',
    '#F0E68C',
    '#FF1493',
    '#00FFFF',
    '#DC143C',
    '#7FFF00',
    '#1E90FF',
    '#FFD700',
    '#2E8B57',
    '#9932CC',
    '#FF4500',
    '#DA70D6',
    '#32CD32',
    '#6B8E23',
    '#BA55D3',
    '#ADFF2F',
    '#9370DB',
    '#3CB371',
    '#FF69B4',
    '#8A2BE2',
    '#00CED1',
    '#FF7F50'
];
