import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useNavigate, Navigate, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Home() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const user = useOutletContext();

    if (user && localStorage.getItem('accessToken') && localStorage.getItem('refreshToken')) return <Navigate to={`/dashboard`} />;

    return (
        <Grid container>
            <Grid xs={12}>
                <Grid container justifyContent={'center'}>
                    <Typography variant={'h3'}>SPCI STAT</Typography>
                </Grid>
            </Grid>
            <Grid xs={12}>
                <Grid container justifyContent={'center'}>
                    <Typography variant={'h5'} sx={{ cursor: 'pointer' }} onClick={() => navigate('/authentication/login')}>
                        {t('login')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid xs={12}>
                <Grid container justifyContent={'center'}>
                    <Typography variant={'h5'} sx={{ cursor: 'pointer' }} onClick={() => navigate('/authentication/registration')}>
                        {t('registration')}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}
