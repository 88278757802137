import { Dialog, DialogContent, FormControlLabel, FormControl, InputLabel, MenuItem, Select, CircularProgress } from '@mui/material';
import { Grid, Table, TableHead, TableBody, TableCell, TableRow, Checkbox, TextField, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { maxHeight } from '@mui/system';
import { axios, upload } from '../utilities/api';
import useSnackbar from '../hooks/useSnackbar';
export default function FileUploadConfig() {
    const { fetcher, file, isVoter } = useOutletContext();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [groups, setGroups] = useState([]);
    const [groupIndex, setGroupIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const { setWarning, setSuccess } = useSnackbar();
    useEffect(() => {
        if (fetcher?.data) {
            // let copy = structuredClone(fetcher?.data);
            // for (let i = 0; i < copy?.length; i++) {
            //     copy[i]['questionList'] = [];
            //     for (let j = 0; j < copy[i]?.questions?.length; j++) {
            //         let guess = [];
            //         console.log(copy[i]);
            //         if (copy[i].guess) {
            //             guess = structuredClone(copy[i].guess);
            //         } else {
            //             for (let k = 0; k < copy[i]?.answers?.length; k++) guess.push({ original: copy[i]?.answers[k], cleaned: copy[i]?.answers[k] });
            //         }
            //         copy[i]['questionList'].push({ name: copy[i]?.questions[j], guess: guess });
            //     }
            // }
            setGroups(fetcher?.data);
        }
    }, [fetcher?.data]);

    useEffect(() => {
        if (groups?.[groupIndex]?.scaleType) setActualScaleType(groups?.[groupIndex]?.scaleType);
    }, [groupIndex, fetcher?.data]);

    useEffect(() => {
        if (!file) navigate('/files/upload');
    });

    function handleScaleTypeChange(value) {
        let copy = structuredClone(groups);
        copy[groupIndex]['scale_type'] = value;
        setGroups(copy);
    }

    function handleValueChange(value, guessIndex, isMiddle = false) {
        let copy = structuredClone(groups);
        if (isMiddle) copy[groupIndex]['guess'][guessIndex]['middle'] = value;
        else copy[groupIndex]['guess'][guessIndex]['cleaned'] = value;
        setGroups(copy);
    }

    function uploadFileWithConfiguratorData() {
        setLoading(true);
        let formData = new FormData();
        formData.append('file', file);
        formData.append('groups', JSON.stringify(groups));
        formData.append('is_voter', isVoter);
        upload(formData)
            .then((response) => {
                setLoading(false);
                setSuccess(t('save_successful'));
                navigate(`/dashboard/${response?.id}/table`);
                // navigate('/files');
            })
            .catch((error) => {
                console.log(error);
                setWarning(t('save_not_successful'));
                setLoading(false);
            });
    }

    function onDecreaseGroupIndex() {
        setGroupIndex((prev) => {
            if (prev - 1 < 0) return groups?.length - 1;
            else return prev - 1;
        });
    }

    function onIncreaseGroupIndex() {
        setGroupIndex((prev) => {
            if (prev + 1 === groups?.length) return 0;
            else return prev + 1;
        });
    }

    return (
        <Dialog open={true} maxWidth={'lg'}>
            <DialogContent style={{ height: '85vh' }}>
                <Grid container>
                    {!loading ? (
                        <>
                            <Grid container pb={1} justifyContent={'center'} alignItems={'center'}>
                                <IconButton onClick={() => onDecreaseGroupIndex()}>
                                    <ArrowBackIcon />
                                </IconButton>
                                {`${t('group')} ${groupIndex + 1} / ${groups?.length}`}
                                <IconButton onClick={() => onIncreaseGroupIndex()}>
                                    <ArrowForwardIcon />
                                </IconButton>
                            </Grid>
                            <Grid>kerdeseket a valaszok alapjan hataroztam meg</Grid>
                            <Grid
                                container
                                alignItems={'flex-start'}
                                sx={{ height: '20vh', overflowY: 'auto', border: '1.5px solid lightgrey', borderRadius: '5px' }}
                                p={1}
                            >
                                {groups?.[groupIndex]?.questions?.map((question, index) => (
                                    <FormControlLabel key={index} control={<Checkbox defaultChecked />} label={question} />
                                ))}
                            </Grid>

                            <Grid container py={1}>
                                <FormControl fullWidth>
                                    <InputLabel>{t('scale_type')}</InputLabel>
                                    <Select
                                        label={t('scale_type')}
                                        onChange={(event) => handleScaleTypeChange(event.target.value)}
                                        value={groups[groupIndex]?.scale_type ?? 'nominal'}
                                    >
                                        <MenuItem value={'nominal'}>{t('nominal')}</MenuItem>
                                        <MenuItem value={'ordinal'}>{t('ordinal')}</MenuItem>
                                        <MenuItem value={'metric'}>{t('metric')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid container py={1}>
                                <Button height={2} disabled>
                                    {t('back_to_original')}
                                </Button>
                            </Grid>
                            <Grid container py={1}>
                                <Button height={2} disabled>
                                    {t('back_to_software_recommendations')}
                                </Button>
                            </Grid>

                            <Grid container sx={{ height: '40vh', overflowY: 'auto', border: '1.5px solid lightgrey', borderRadius: '5px' }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: 'white' }}>
                                            <TableCell sx={{ width: '50%' }} align={'center'}>
                                                {t('original')}
                                            </TableCell>
                                            <TableCell sx={{ width: '50%' }} align={'center'}>
                                                {t('values_for_calculation')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {groups[groupIndex]?.guess?.map((answer, index) => (
                                            <TableRow key={index}>
                                                <TableCell sx={{ width: '50%' }} align={'center'}>
                                                    {answer?.original}
                                                </TableCell>
                                                <TableCell sx={{ width: '50%' }} align={'center'}>
                                                    <TextField
                                                        onChange={(event) => handleValueChange(event.target.value, index, Boolean(answer?.middle))}
                                                        value={answer?.middle ?? answer?.cleaned}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </>
                    ) : (
                        <Grid container justifyContent={'center'} alignItems={'center'} sx={{ height: '80vh' }}>
                            <CircularProgress size={'5rem'} />
                        </Grid>
                    )}
                    <Grid container justifyContent={'space-between'} py={1}>
                        <Button onClick={() => navigate('/files/upload')} color="error" sx={{ width: 'auto' }}>
                            {t('cancel')}
                        </Button>
                        <Button onClick={() => uploadFileWithConfiguratorData()} sx={{ width: 'auto' }}>
                            {t('save_changes_in_group')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
