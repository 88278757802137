import { useNavigate } from 'react-router-dom';
import { axios } from '../utilities/api';

export default function useLogout() {
    const navigate = useNavigate();

    return () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        axios.defaults.headers.common['Authorization'] = "";
        navigate('/');
    };
}
