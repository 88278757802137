import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';

const link = forwardRef((props, ref) => <RouterLink ref={ref} {...props} />);

export default function Link({ children, sx, to, className }) {
    return (
        <Button className={className} component={link} fullWidth={false} sx={sx} to={to} variant={'text'}>
            {children}
        </Button>
    );
}
