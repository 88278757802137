import React, { useEffect, useRef, useState } from 'react';
import { useFetcher, useParams, useActionData } from 'react-router-dom';
import { Dialog, DialogContent, FormControlLabel, FormControl, InputLabel, MenuItem, Select, Typography, InputAdornment } from '@mui/material';
import { Grid, Table, TableHead, TableBody, TableCell, TableRow, Checkbox, TextField, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
export default function EditHeaderDialog(props) {
    const { t } = useTranslation();
    const { fileId } = useParams();
    const headerRef = useRef();
    const originalHeader = props?.headerText;
    const fetcher = useFetcher();

    const setHeader = () => {
        fetcher.submit({ fileId, original: originalHeader, new: headerRef?.current?.value }, { action: '/dashboard/11/edit-header', method: 'post' });
    };
    useEffect(() => {
        if (fetcher?.data?.status === 200) {
            props.setEditHeaderClose();
            props.load();
        }
    }, [fetcher]);
    return (
        <Dialog open={props.editDiagOpen} onClose={props.setEditHeaderClose}>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h4">{t('header_rename')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>{originalHeader}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField inputRef={headerRef} label={t('header')} name="header" defaultValue={originalHeader} />
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="outlined" onClick={props.setEditHeaderClose}>
                            {t('cancel')}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={setHeader}>{t('save')}</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
