import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteError, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Link from './Link';

export default function Error() {
    const error = useRouteError();
    const navigate = useNavigate();
    const { t } = useTranslation();

    if (error?.status === 401) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    }

    if (typeof error === 'object')
        return (
            <Grid
                alignItems={'center'}
                container
                justifyContent={'center'}
                padding={5}
                sx={{ height: '100vh', backgroundColor: 'linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147))'}}
            >
                <Grid component={Paper} md={6} lg={5} sm={8} xl={4} xs={12}>
                    <Grid container justifyContent={'center'}>
                        <Grid padding={1} xs={12}>
                            {error?.status === 401 ? (
                                <Fragment>
                                    <Grid paddingY={1} xs={12}>
                                        <Typography textAlign={'center'}>Az Ön munkamenete lejárt, kérjük, jelentkezzen be újból!</Typography>
                                    </Grid>
                                    <Grid container justifyContent={'center'} paddingY={1} xs={12}>
                                        <Link to={'/authentication/login'} />
                                    </Grid>
                                </Fragment>
                            ) : error?.status === 502 ? (
                                <Grid paddingY={1} xs={12}>
                                    <Typography textAlign={'center'}>Szerverünk átmenetileg nem elérhető, kérjük, próbálja meg később!</Typography>
                                </Grid>
                            ) : (
                                <Fragment>
                                    <Divider>
                                        <Typography variant={'h4'}>Hiba</Typography>
                                    </Divider>
                                    {Object.keys(error)?.map((key) => (
                                        <Grid
                                            container
                                            justifyContent={'space-between'}
                                            key={key}
                                            padding={2}
                                            sx={{
                                                backgroundColor:
                                                    key === 'status'
                                                        ? Number(error[key]) > 499 && Number(error[key]) < 599
                                                            ? 'rgba(255,100,100,0.5)'
                                                            : Number(error[key]) > 399 && Number(error[key]) < 499
                                                            ? 'rgba(255,200,10,0.5)'
                                                            : null
                                                        : null,
                                            }}
                                        >
                                            <Grid>
                                                <Typography variant={'h6'}>{key}</Typography>
                                            </Grid>
                                            <Grid>
                                                <Typography variant={'h6'}>
                                                    {typeof error[key] === 'object' ? JSON.stringify(error[key]) : error[key]}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Fragment>
                            )}
                            <Button onClick={() => navigate('/')}>{t('back_to_start_page')}</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );

    return <h1>Hiba</h1>;
}

