import { useState } from 'react';
import { useOutletContext, Outlet, useNavigate, useNavigation } from 'react-router-dom';
import { AppBar, MenuItem, Divider, Menu, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AnalysisButton from '../components/AnalysisButton';

import bar_icon from '../../src/assets/icons/bar_colored.png';
import anova_icon from '../../src/assets/icons/anova_colored.png';
import cluster_icon from '../../src/assets/icons/cluster_colored.png';
import discriminant_icon from '../../src/assets/icons/classification_colored.png';
import crosstable_icon from '../../src/assets/icons/crosstable_colored.png';
import regression_icon from '../../src/assets/icons/regression_colored.png';

const LargeIconSize = 40;
const SmallIconSize = 22;

const BarChartIcon = () => <img src={bar_icon} alt="Barchart" style={{ width: `${LargeIconSize}px`, height: `${LargeIconSize}px` }} />;
const AnovaIcon = () => <img src={anova_icon} alt="Anova" style={{ width: `${LargeIconSize}px`, height: `${LargeIconSize}px` }} />;
const ClusterIcon = () => <img src={cluster_icon} alt="Cluster" style={{ width: `${LargeIconSize}px`, height: `${LargeIconSize}px` }} />;
const DiscriminantIcon = () => <img src={discriminant_icon} alt="Discriminant" style={{ width: `${LargeIconSize}px`, height: `${LargeIconSize}px` }} />;
const CrosstableIcon = () => <img src={crosstable_icon} alt="Crosstable" style={{ width: `${LargeIconSize}px`, height: `${LargeIconSize}px` }} />;
const RegressionIcon = () => <img src={regression_icon} alt="Regression" style={{ width: `${LargeIconSize}px`, height: `${LargeIconSize}px` }} />;

export default function Dashboard() {
    const navigate = useNavigate();
    const { files, sidebarWidth, user } = useOutletContext();
    const { t } = useTranslation();

    const [anchorElDescriptives, setAnchorElDescriptives] = useState(null);
    const [analysisType, setAnalysisType] = useState('');

    return (
        <Grid xs={12}>
            <Grid container justifyContent={'center'}>
                {!files?.length ? (
                    <Typography sx={{ cursor: 'pointer', color: '#000' }} variant={'h5'} onClick={() => navigate('/files/upload')}>
                        {t('Click here to upload file!')}
                    </Typography>
                ) : null}
                <Outlet context={{ analysisType, sidebarWidth, user }} />
            </Grid>
        </Grid>
    );
}
