import React, { useEffect } from 'react';
import { Divider, Paper, Typography } from '@mui/material';
import { useFetcher, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2';

import nominal_icon from '../assets/icons/nominal.png';
import ordinal_icon from '../assets/icons/ordinal.png';
import interval_icon from '../assets/icons/interval.png';
import ratio_icon from '../assets/icons/ratio.png';

const NominalIcon = () => <img src={nominal_icon} alt="nominal" width="20" height="20" />;
const OrdinalIcon = () => <img src={ordinal_icon} alt="ordinal" width="20" height="20" />;
const RatioIcon = () => <img src={ratio_icon} alt="ratio" width="20" height="20" />;

export default function ScaleTypeSelectDialog({ header, setHeader }) {
    const { t } = useTranslation();
    const fetcher = useFetcher();
    const { fileId } = useParams();

    function handleChange(value) {
        if (header.type === value || !header?.id) {
            setHeader(false);
            return;
        }
        let tmp = { ...header };
        tmp['type'] = value;
        setHeader(tmp);
        fetcher.submit({ id: header.id, type: value }, { action: `/dashboard/${fileId}/scale-edit/${header.id}`, method: 'post' });
    }

    useEffect(() => {
        if (fetcher?.state === 'loading') setHeader(false);
    }, [fetcher?.state]);

    return (
        <Grid container>
            <Grid
                xs={12}
                onClick={() => handleChange('nominal')}
                paddingY={1}
                sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#70BFB2' }, transition: 'background-color 0.7s ease' }}
            >
                <NominalIcon />
                <Typography variant={'caption1'} pl={1}>
                    {t('nominal')}
                </Typography>
            </Grid>
            <Grid xs={12}>
                <Divider />
                <Divider />
            </Grid>
            <Grid
                xs={12}
                onClick={() => handleChange('ordinal')}
                paddingY={1}
                sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#70BFB2' }, transition: 'background-color 0.7s ease' }}
            >
                <OrdinalIcon />
                <Typography variant={'caption1'} pl={1}>
                    {t('ordinal')}
                </Typography>
            </Grid>
            <Grid xs={12}>
                <Divider />
                <Divider />
            </Grid>
            <Grid
                xs={12}
                onClick={() => handleChange('metric')}
                paddingY={1}
                sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#70BFB2' }, transition: 'background-color 0.7s ease' }}
            >
                <RatioIcon />
                <Typography variant={'caption1'} pl={1}>
                    {t('metric')}
                </Typography>
            </Grid>
        </Grid>
    );
}
