import React from 'react'
import { Button, Divider, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/';

export default function AnalysisButton({ text, Icon, onClick, active, disabled }) {
    return (
        <Grid onClick={onClick} container pt={.5} justifyContent={'center'} sx={{ width: '150px', cursor: 'pointer', color: '#000', height: '72px', backgroundColor: active ? '#13f164' : "" }}>
            <Grid >
                <Icon />
            </Grid>
            <Grid xs={12} textAlign={'center'}>
                <Typography sx={{fontSize: '17px'}}>{text}</Typography>
            </Grid>
        </Grid>

    )
}
