import axios from 'axios';
import jwt_decode from 'jwt-decode';

const baseURL = import.meta.env.DEV ? 'http://localhost:8000' : `https://${location.hostname}`;
console.warn(location);

const instance = axios.create({
    baseURL,
    timeout: 600000
});

instance.interceptors.request.use(async function (config) {
    if (config?.data instanceof FormData) Object.assign(config?.headers, { 'Content-Type': 'multipart/form-data' });

    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) return config;

    const expired = jwt_decode(accessToken)?.exp < new Date() / 1000;

    if (!expired) {
        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
    }

    const refreshToken = localStorage.getItem('refreshToken');

    if (refreshToken) {
        try {
            const response = await axios.post(`${baseURL}/auth/token/refresh/`, { refresh: refreshToken });
            localStorage.setItem('accessToken', response?.data?.access);
            localStorage.setItem('refreshToken', response?.data?.refresh);
            config.headers.Authorization = `Bearer ${response?.data?.access}`;
            return config;
        } catch (error) {
            console.warn('AXIOS (There was no successful response for new tokens.)', error);
            if (error?.response?.status === 401) {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                location.assign(import.meta.env.DEV ? 'http://localhost:5173' : `https://${location.hostname}`);
            }
        }
    }

    return config;
});

export async function changePassword(data) {
    try {
        const response = await instance.post('auth/change-password/', data);
        return response?.status;
    } catch (error) {
        throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 832 };
    }
}

export async function deleteAccount() {
    try {
        const response = await instance.post('auth/delete-account/');
        return response?.status;
    } catch (error) {
        throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 833 };
    }
}

export async function getUser(id) {
    const URL = id ? `/auth/users/${id}` : '/auth/users/authenticated/';

    try {
        const response = await instance.get(URL);
        return response?.data;
    } catch (error) {
        if (error?.response?.status === 401) return null;
        throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 709 };
    }
}

export async function getScaleTypes(id) {
    try {
        const response = await instance.get(`/files/get-headers/${id}`);
        return response?.data;
    } catch (error) {
        throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 721 };
    }
}

export async function getUsers() {
    try {
        const response = await instance.get('/auth/users/');
        return response?.data;
    } catch (error) {
        throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 720 };
    }
}

export async function getUserFiles() {
    try {
        const response = await instance.get('/files/user-files');
        return response?.data;
    } catch (error) {
        throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 721 };
    }
}

export async function getUserFile(id, only_scale_types) {
    try {
        const response = await instance.get(`/files/user-files/${id}`);

        return response?.data;
    } catch (error) {
        throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 722 };
    }
}

export async function googleLogin(data) {
    try {
        const response = await instance.post('auth/google/', data);
        return response?.data;
    } catch (error) {
        throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 829 };
    }
}

export async function login(data) {
    try {
        const response = await instance.post('/auth/token/', data);
        localStorage.setItem('accessToken', response?.data?.access);
        localStorage.setItem('refreshToken', response?.data?.refresh);
        const accessTokenDecoded = jwt_decode(response?.data?.access);
        return { data: accessTokenDecoded?.user, status: response?.status };
    } catch (error) {
        if (error?.response?.status === 401) return { status: error?.response?.status };
        throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 710 };
    }
}

export async function loginSocial(accessToken, provider) {
    try {
        const response = await instance.post('/auth/social-auth/', { access_token: accessToken, provider: provider });
        localStorage.setItem('accessToken', response?.data?.access);
        localStorage.setItem('refreshToken', response?.data?.refresh);
        const accessTokenDecoded = jwt_decode(response?.data?.access);

        return { data: { id: accessTokenDecoded?.user_id }, status: response?.status };
    } catch (error) {
        throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 711 };
    }
}

export async function newPassword(data) {
    try {
        const response = await instance.post(`/auth/password_reset/confirm/`, data);
        return response?.status;
    } catch (error) {
        throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 712 };
    }
}

export async function registration(data) {
    try {
        const response = await instance.post('/auth/users/', data);
        return response?.status;
    } catch (error) {
        throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 713 };
    }
}

export async function renameDocument(data, id) {
    try {
        const response = await instance.post(`/files/user-files/${id}/rename/`, data);
        return response?.status;
    } catch (error) {
        throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 719 };
    }
}

export async function resetPassword(data) {
    try {
        const response = await instance.post('/auth/password_reset/', data);
        return response?.status;
    } catch (error) {
        throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 714 };
    }
}

export async function updateUser(data) {
    try {
        const response = await instance.patch(`/auth/user_setting/`, data);
        // return response?.status;
        return response;
    } catch (error) {
        throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 717 };
    }
}

export async function uploadFile(formData) {
    try {
        const response = await instance.post('/files/upload/config/', formData);
        return response?.data;
    } catch (error) {
        throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 717 };
    }
}

export async function upload(formData) {
    try {
        const response = await instance.post('/files/upload/', formData);
        return response?.data;
    } catch (error) {
        throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 717 };
    }
}

export async function deleteFile(id) {
    try {
        const response = await instance.delete(`/files/user-files/${id}/delete/`);
        return response?.status;
    } catch (error) {
        throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 718 };
    }
}

export async function loadSurveyData(id) {
    try {
        const response = await instance.get(`/files/survey/${id}`);
        return response?.data;
    } catch (error) {
        throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 719 };
    }
}

export async function scaleTypeChange(data, id) {
    try {
        const response = await instance.patch(`/files/header/${id}`, data);
        return response;
    } catch (error) {
        throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 720 };
    }
}
export async function newPasswordWithConfirmAction(data) {
    try {
        const response = await instance.post('/auth/change-password-with-confirm/', data);
        return response;
    } catch (error) {
        // throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 834 };
        if (error?.response?.status === 400) return error?.response;
    }
}

export async function editHeaderAction(data) {
    try {
        const response = await instance.post('/files/edit-header/', data);
        return response;
    } catch (error) {
        return error?.response;
    }
}


export { instance as axios };
