import { useState, React, useEffect } from 'react';
import {
    Slider,
    Button,
    IconButton,
    Table,
    TableContainer,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Dialog,
    DialogContent,
    TextField
} from '@mui/material';
import { useOutletContext } from 'react-router';
import { useTranslation } from 'react-i18next';
import { axios } from '../utilities/api';
import { Droppable, DragDropContext } from '@hello-pangea/dnd';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import QuestionsDraggable from '../components/QuestionsDraggable';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Plot from 'react-plotly.js';
import HelpIcon from '@mui/icons-material/Help';
import { useNavigate, Outlet } from 'react-router-dom';
import useSnackbar from '../hooks/useSnackbar';
export default function Clustering() {
    const { questions, user } = useOutletContext();

    const { t } = useTranslation();
    const [selectedX, setSelectedX] = useState([]);
    const [groupCount, setGroupCount] = useState(2);
    const [maxIterations, setMaxIterations] = useState(1000);
    const [plotWidth, setPlotWidth] = useState(0);
    const [data, setData] = useState(null);
    const [infoDialog, setInfoDialog] = useState(false);
    const { setWarning } = useSnackbar();
    const navigate = useNavigate();
    function loadClusteringData() {
        axios
            .post(
                '/analysis/clustering/',
                {
                    questions: selectedX,
                    groupCount: groupCount,
                    maxIterations: maxIterations
                },
                { timeout: 900000 }
            )
            .then((res) => {
                console.log(res.data);
                setData(res.data);
            })
            .catch((err) => {
                console.log(err);
                setWarning(t('query_not_successful'));
            });
    }

    useEffect(() => {
        const handleResize = () => {
            setPlotWidth(document.getElementById('outSideContainer')?.offsetWidth);
            // console.log(document.getElementById('outSideContainer')?.offsetWidth, document.getElementById('outSideContainer')?.clientWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <DragDropContext
            onDragEnd={(event) => {
                if (event.source.droppableId === event.destination?.droppableId) return;

                if (event.source.droppableId === 'questions' && event.destination?.droppableId === 'selectedX') {
                    if (selectedX?.includes(event.draggableId)) return;
                    let temp = [...selectedX];
                    temp.push(event.draggableId);
                    setSelectedX(temp);
                }
            }}
        >
            <Dialog open={infoDialog}>
                <DialogContent>
                    <Grid container justifyContent={'center'}>
                        <Typography align="center">{infoDialog}</Typography>
                    </Grid>
                    <Grid container justifyContent={'center'} pt={2}>
                        <Button sx={{ width: 'auto' }} onClick={() => setInfoDialog(false)}>
                            {t('close')}
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Grid item xs={12} py={1}>
                <Typography variant="h5" align="center">
                    {t('clustering')}
                    {!user?.details && (
                        <IconButton onClick={() => setInfoDialog(t('cluster_help_text'))}>
                            <HelpIcon />
                        </IconButton>
                    )}
                </Typography>
                {user?.details && (
                    <Grid container justifyContent={'center'}>
                        <Typography variant={'body2'} align="center">
                            {t('cluster_help_text')}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid container id="outSideContainer">
                <Grid xs={12}>
                    <QuestionsDraggable questions={questions} />
                </Grid>
                <Grid xs={12} pt={5}>
                    <Typography variant="h6">{t('variables')}</Typography>
                    <Droppable droppableId="selectedX">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={{
                                    backgroundColor: snapshot.isDraggingOver ? '#4C9F93' : '#cbe6e2',
                                    width: '100%',
                                    height: '100%',
                                    borderRadius: '1rem'
                                }}
                                {...provided.droppableProps}
                            >
                                {selectedX?.map((question, index) => {
                                    return (
                                        <Grid
                                            key={index}
                                            container
                                            justifyContent="center"
                                            mt={2}
                                            py={1}
                                            sx={{ border: '1px grey solid', borderRadius: '1rem', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px;' }}
                                        >
                                            <Grid xs={10} px={3}>
                                                {question?.substring(question?.indexOf('_') + 1)}
                                            </Grid>
                                            <Grid xs={2}>
                                                <Grid container justifyContent={'flex-end'}>
                                                    <IconButton
                                                        onClick={() => {
                                                            let temp = [...selectedX];
                                                            temp.splice(index, 1);
                                                            setSelectedX(temp);
                                                        }}
                                                    >
                                                        <HighlightOffIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </Grid>
            </Grid>
            <Grid container pt={10}>
                <Grid xs={12} px={1}>
                    <Typography variant="h6">{t('group_count')}</Typography>
                    <Slider
                        defaultValue={groupCount}
                        aria-label="Small"
                        valueLabelDisplay="auto"
                        step={1}
                        marks
                        min={2}
                        max={10}
                        onChange={(event, value) => setGroupCount(value)}
                    />
                </Grid>
                <Grid xs={12} px={1}>
                    <Typography variant="h6">{t('max_iterations')}</Typography>
                    <Slider
                        defaultValue={maxIterations}
                        aria-label="Small"
                        valueLabelDisplay="auto"
                        step={100}
                        marks
                        min={100}
                        max={5000}
                        onChange={(event, value) => setMaxIterations(value)}
                    />
                </Grid>
            </Grid>
            <Grid container pt={10}>
                <Button disabled={selectedX?.length < 3} onClick={() => loadClusteringData()}>
                    {t('check')}
                </Button>
            </Grid>
            {data && (
                <>
                    <Grid container pt={4}>
                        <Button disabled={selectedX?.length < 3} onClick={() => navigate('save')}>
                            {t('save_results')}
                        </Button>
                    </Grid>
                    <Grid container pt={10}>
                        <Grid xs={12}>
                            <Grid container justifyContent="center">
                                <Plot data={data.clusters} layout={{ width: plotWidth, height: plotWidth < 500 ? 500 : 1000 }} />
                            </Grid>
                        </Grid>
                        <Grid xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">{''}</TableCell>
                                            {data.cluster_freq_table.map((val, idx) => (
                                                <TableCell key={val} align="center">{`${t('cluster')} ${idx + 1}`}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            key={''}
                                            sx={{
                                                '&:last-child td, &:last-child th': { border: 0 }
                                            }}
                                        >
                                            <TableCell align="center">{t('user_count')}</TableCell>
                                            {data.cluster_freq_table.map((val) => (
                                                <TableCell key={val} align="center">
                                                    {val}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <Outlet context={{ questions, selectedX, data }} />
                </>
            )}
        </DragDropContext>
    );
}
