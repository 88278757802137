import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useFetcher, useOutletContext, Outlet } from 'react-router-dom';

// Handsontable
import 'handsontable/dist/handsontable.full.min.css';
import { registerAllModules } from 'handsontable/registry';
import { HotTable } from '@handsontable/react';

import Grid from '@mui/material/Unstable_Grid2';
import Skeleton from '@mui/material/Skeleton';
import Popover from '@mui/material/Popover';

import EditHeaderDialog from '../components/EditHeaderDialog';
import ScaleTypeSelect from '../components/ScaleTypeSelectPopper';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { loadSurveyData, editHeaderAction } from '../utilities/api';

import * as Space from 'react-spaces';
import { TextField, InputAdornment, Button } from '@mui/material';
import '../assets/custom.css';

registerAllModules();

export async function loader({ params }) {
    return await loadSurveyData(params?.fileId);
}

export default function SurveyTable() {
    const { t } = useTranslation();

    const { fileId } = useParams();
    const scaleTypeFetcher = useFetcher();
    const surveyDataFetcher = useFetcher();
    const { sidebarWidth, user } = useOutletContext();

    const [anchorElScaleTypePopper, setAnchorElScaleTypePopper] = useState(null);

    const [scaleSelectOpen, setScaleSelectOpen] = useState(false);
    const [editHeaderOpen, setEditHeaderOpen] = useState(false);
    const [headerText, setHeaderText] = useState('');

    const tableRef = useRef();

    const onCellClick = (event, cellProps) => {
        if (cellProps?.rowIndex === 0) {
            setAnchorElScaleTypePopper(event.currentTarget);
            setScaleSelectOpen({
                id: surveyDataFetcher?.data?.columns[cellProps.columnIndex].name.split('_')[0],
                type: scaleTypeFetcher.data[0]?.[
                    Object.keys(scaleTypeFetcher?.data[0])?.find((key) => key === surveyDataFetcher?.data?.columns[cellProps.columnIndex].name)
                ],
                header: surveyDataFetcher?.data?.columns[cellProps.columnIndex].header
            });
        } else {
            setHeaderText(cellProps?.header);
            setEditHeaderOpen(true);
        }
    };

    const afterSelection = (row1, col1, row2, col2, preventScrolling, selectionLayerLevel) => {
        if (row1 == -1) {
            // console.log(tableRef?.current.hotInstance.getColHeader(col1));
            setHeaderText(tableRef?.current.hotInstance.getColHeader(col1));
            setEditHeaderOpen(true);
        }
        if (row1 != row2 || col1 != col2) return;
        if (row1 == 0) {
            setAnchorElScaleTypePopper(tableRef?.current?.hotInstance.rootElement);
            // console.log(tableRef?.current?.hotInstance.rootElement);
            setScaleSelectOpen({
                id: surveyDataFetcher?.data?.columns[col1].name.split('_')[0],
                type: scaleTypeFetcher.data[0]?.[Object.keys(scaleTypeFetcher?.data[0])?.find((key) => key === surveyDataFetcher?.data?.columns[col1].name)],
                header: surveyDataFetcher?.data?.columns[col1].header
            });
        }
        // console.log(tableRef?.current.hotInstance.getData(row1, col1, row2, col2)[0][0]);
    };

    const afterColumnResize = (new_size, curr_col, isdoubleclick) => {
        const headerValue = tableRef?.current?.hotInstance?.getColHeader(curr_col);
        editHeaderAction({ fileId: fileId, original: headerValue, newWidth: new_size });
    };

    useEffect(() => {
        scaleTypeFetcher?.load(`/dashboard/${fileId}/scale-types`);
    }, [JSON.stringify(surveyDataFetcher?.data)]);

    useEffect(() => {
        surveyDataFetcher.load(`/dashboard/${fileId}/survey`);
    }, [fileId]);

    const renderColumnContextMenu = useCallback((menuProps) => {
        // ne lehessen eltrejteni oszlopokat, mert akkor elcsúszik az oszlop indexelés
        let index = menuProps.items.findIndex((item) => item.label === 'Columns');
        if (index !== -1) menuProps.items.splice(index, 1);
        if (menuProps?.items[menuProps.items.length - 1] === '-') menuProps.items.pop();
    }, []);

    function subsctractFromString(value) {
        console.log(value);
        return `${Number(value?.replace('px', '')) - 65}px`;
    }

    const canBeOpen = open && Boolean(anchorElScaleTypePopper);
    const id = canBeOpen ? 'transition-popper' : undefined;

    return (
        <Grid xs={12} p={3} sx={{ minHeight: 'calc(100vh - 80px)' }}>
            <EditHeaderDialog
                editDiagOpen={editHeaderOpen}
                headerText={headerText}
                setEditHeaderClose={(saved) => {
                    setEditHeaderOpen(false);
                }}
                load={() => {
                    surveyDataFetcher.load(`/dashboard/${fileId}/survey`);
                }}
            />
            <Popover
                id={id}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                onClose={() => setScaleSelectOpen(false)}
                open={Boolean(scaleSelectOpen)}
                anchorEl={anchorElScaleTypePopper}
                sx={{ zIndex: 10 }}
            >
                <div style={{ width: '300px' }}>
                    <ScaleTypeSelect header={scaleSelectOpen} setHeader={setScaleSelectOpen} />
                </div>
            </Popover>
            <Space.ViewPort top={80} left={sidebarWidth + 10} right={10} bottom={10} style={{ backgroundColor: 'white', borderRadius: '10px' }}>
                <Space.LeftResizable size={'50%'}>
                    <Grid container justifyContent={'center'} alignItems={'center'} style={{ height: '100%', borderRight: '4px green solid' }}>
                        <Grid xs={12} p={2} style={{ height: '100%' }} id="tableOuter">
                            {surveyDataFetcher.state === 'loading' ? (
                                <Grid xs={12} pt={2}>
                                    <Skeleton
                                        variant="rectangular"
                                        width={'100%'}
                                        height={subsctractFromString(getComputedStyle(document.getElementById('tableOuter')).height)}
                                    />
                                </Grid>
                            ) : (
                                <HotTable
                                    id={id}
                                    ref={tableRef}
                                    data={(scaleTypeFetcher?.data ?? [{}])?.concat(surveyDataFetcher?.data?.rows)}
                                    width={'100%'}
                                    height={'100%'}
                                    persistentStateLoad={true}
                                    afterSelection={afterSelection}
                                    manualColumnResize={true}
                                    className="custom-survey-table"
                                    columnHeaderHeight={100}
                                    colWidths={(surveyDataFetcher?.data?.columns ?? [])?.map((col) => col.width)}
                                    colHeaders={(surveyDataFetcher?.data?.columns ?? [])?.map((col) => col.header)}
                                    licenseKey="non-commercial-and-evaluation"
                                    afterColumnResize={afterColumnResize}
                                />
                                // <ReactDataGrid
                                //     idProperty="id"
                                //     columns={surveyDataFetcher?.data?.columns ?? []}
                                //     dataSource={(scaleTypeFetcher?.data ?? [{}])?.concat(surveyDataFetcher?.data?.rows)}
                                //     pagination
                                //     defaultLimit={100}
                                //     emptyText="No data found"
                                //     style={{ height: 'calc(100% - 55px)' }}
                                //     onCellClick={onCellClick}
                                //     sortable={false}
                                //     renderColumnContextMenu={renderColumnContextMenu}
                                //     headerHeight={200}
                                // />
                            )}
                        </Grid>
                    </Grid>
                </Space.LeftResizable>
                <Space.Fill size={'50%'} style={{ minHeight: '100%', overflowY: 'scroll' }}>
                    <Grid
                        container
                        justifyContent={'center'}
                        alignItems={'flex-start'}
                        style={{ minHeight: '100%', borderLeft: '4px green solid', overflow: 'none' }}
                    >
                        <Grid xs={12} p={2} style={{ height: '100%' }}>
                            <Outlet context={{ questions: scaleTypeFetcher?.data ?? [], user }} />
                        </Grid>
                    </Grid>
                </Space.Fill>
            </Space.ViewPort>
        </Grid>
    );
}
