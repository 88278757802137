import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export default function LanguageSelect({ language, setLanguage }) {
    const { t } = useTranslation();

    return (
        <FormControl>
            <InputLabel>{t('language')}</InputLabel>
            <Select label={t('language')} name={'language'} onChange={(event) => setLanguage(event.target.value)} value={language}>
                <MenuItem value={'en'}>{t('language_en')}</MenuItem>
                <MenuItem value={'en-us'}>{t('language_en_us')}</MenuItem>
                <MenuItem value={'hu'}>{t('language_hu')}</MenuItem>
            </Select>
        </FormControl>
    );
}
