import Grid from '@mui/material/Unstable_Grid2';

import logo from '/icon.png';

export default function Loading() {
    return (
        <Grid container justifyContent={'center'} p={1} xs={12}>
            <img alt={'Loading...'} className={'rotate'} src={logo} style={{ maxHeight: 64, maxWidth: 'auto' }} />
        </Grid>
    );
}
