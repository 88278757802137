import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLoaderData } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import useLogout from '../hooks/useLogout';
import useSnackbar from '../hooks/useSnackbar';


export default function RootLayout() {
    const { message, open, setOpen, severity } = useSnackbar();
    const { i18n } = useTranslation();
    const logout = useLogout();
    const user = useLoaderData();

    useEffect(() => {
        if (user?.language && i18n.language !== user.language) i18n.changeLanguage(user.language);
    }, [user?.language]);

    return (
        <>
            <Outlet
                context={{
                    user: { ...user }
                }}
            />
            <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} autoHideDuration={5000} open={open} onClose={() => setOpen(false)}>
                <Alert onClose={() => setOpen(false)} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
}
