import { Fragment, useEffect, useMemo, useState } from 'react';
import { IMaskMixin } from 'react-imask';
import { useTranslation } from 'react-i18next';
import { Form, useActionData, useNavigate, useNavigation } from 'react-router-dom';
import analysis from '../../assets/icons/analysis.png';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MuiLink from '@mui/material/Link';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';

import LanguageSelect from '../../components/LanguageSelect';
import Link from '../../components/Link';

import useSnackbar from '../../hooks/useSnackbar';
import countries_list from 'countries-list';
import Button from '@mui/material/Button';

const MaskedInput = IMaskMixin(({ ...props }) => {
    return <TextField {...props} />;
});

export default function Registration() {
    const countries = countries_list.countries;
    const { i18n, t } = useTranslation();

    const { setWarning } = useSnackbar();

    const navigate = useNavigate();
    const navigation = useNavigation();
    const status = useActionData();

    const [country, setCountry] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [language, setLanguage] = useState(i18n.language);
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false);

    const passwordInvalid = useMemo(() => (password?.length && passwordConfirm?.length ? password !== passwordConfirm : false), [password, passwordConfirm]);

    const submitDisabled = useMemo(
        () =>
            !email?.length ||
            !firstName?.length ||
            !lastName?.length ||
            passwordInvalid ||
            !termsAndConditionsAccepted || [email, firstName, lastName, password, passwordConfirm, passwordInvalid, termsAndConditionsAccepted]
    );

    useEffect(() => {
        if (status === 207) setWarning(t('email_already_in_db'));
        if (status !== 201) return;

        if (i18n.language !== language) i18n.changeLanguage(language);

        navigate('/authentication/login');
    }, [status]);

    return (
        <Fragment>
            <Form action={location.pathname} method={'post'}>
                <Grid container paddingY={1} xs={12} alignItems={'center'} justifyContent={'center'}>
                    <Grid xs={1}>
                        <img src={analysis} height={40} />
                    </Grid>
                    <Grid xs>
                        <Typography align={'center'} variant={'h5'} sx={{ display: 'block', paddingLeft: '10px' }}>
                            {t('registration')}
                        </Typography>
                    </Grid>
                    <Grid xs={1}></Grid>
                </Grid>
                <Box
                    sx={{
                        display: 'flex',
                        gap: { xs: 0, sm: 1 },
                        flexDirection: { xs: 'column', sm: 'row' }
                    }}
                >
                    <Grid paddingY={1} xs={12} sm={6}>
                        <TextField
                            InputProps={{
                                endAdornment: <Typography color={'text.secondary'} sx={{ ml: 2 }}>{`${Number(firstName?.length)}/255`}</Typography>
                            }}
                            label={t('first_name')}
                            name={'firstName'}
                            required
                            onChange={(event) => setFirstName(event.target.value)}
                            value={firstName}
                        />
                    </Grid>
                    <Grid paddingY={1} xs={12} sm={6}>
                        <TextField
                            InputProps={{
                                endAdornment: <Typography color={'text.secondary'} sx={{ ml: 2 }}>{`${Number(lastName?.length)}/255`}</Typography>
                            }}
                            label={t('last_name')}
                            name={'lastName'}
                            required
                            onChange={(event) => setLastName(event.target.value)}
                            value={lastName}
                        />
                    </Grid>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: { xs: 0, sm: 1 },
                        flexDirection: { xs: 'column', sm: 'row' }
                    }}
                >
                    <Grid paddingY={1} xs={12}>
                        <TextField
                            InputProps={{
                                endAdornment: <Typography color={'text.secondary'} sx={{ ml: 2 }}>{`${Number(email?.length)}/255`}</Typography>
                            }}
                            label={t('email_address')}
                            name={'email'}
                            required
                            onChange={(event) => setEmail(event.target.value)}
                            value={email}
                        />
                    </Grid>
                    <Grid paddingY={1} xs={12}>
                        <FormControl fullWidth required>
                            <InputLabel id="country-label">{t('country')}</InputLabel>
                            <Select
                                required
                                name="country"
                                value={country}
                                label={t('country')}
                                onChange={(e) => {
                                    setCountry(e.target.value);
                                }}
                            >
                                {Object.keys(countries)
                                    .sort((code1, code2) => countries[code1].name.localeCompare(countries[code2].name)) // sort countries by name (not code)
                                    .map((countryCode) => {
                                        let country = countries[countryCode];
                                        return (
                                            <MenuItem key={countryCode} value={countryCode}>
                                                {`${country.emoji} ${country.name} (${countryCode})`}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: { xs: 0, sm: 1 },
                        flexDirection: { xs: 'column', sm: 'row' }
                    }}
                >
                    <Grid paddingY={1} xs={12}>
                        <TextField
                            error={passwordInvalid}
                            helperText={passwordInvalid ? t('passwords_do_not_match') : null}
                            InputProps={{
                                endAdornment: (
                                    <IconButton edge={'end'} onClick={() => setPasswordVisible((current) => !current)}>
                                        {passwordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                )
                            }}
                            label={t('password')}
                            name={'password'}
                            onChange={(event) => setPassword(event.target.value)}
                            type={passwordVisible ? 'text' : 'password'}
                            value={password}
                        />
                    </Grid>
                    <Grid paddingY={1} xs={12}>
                        <TextField
                            InputProps={{
                                endAdornment: (
                                    <IconButton edge={'end'} onClick={() => setPasswordConfirmVisible((current) => !current)}>
                                        {passwordConfirmVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                )
                            }}
                            label={t('password_confirm')}
                            onChange={(event) => setPasswordConfirm(event.target.value)}
                            type={passwordConfirmVisible ? 'text' : 'password'}
                            value={passwordConfirm}
                        />
                    </Grid>
                </Box>

                <Grid paddingY={1} xs={12}>
                    <LanguageSelect language={language} setLanguage={setLanguage} />
                </Grid>

                {i18n.language === 'hu' ? (
                    <Grid alignItems={'center'} container paddingY={1} xs={12}>
                        <Grid xs={2} sm={1}>
                            <Checkbox
                                checked={termsAndConditionsAccepted}
                                onChange={() => setTermsAndConditionsAccepted((current) => !current)}
                                value={termsAndConditionsAccepted}
                            />
                        </Grid>
                        <Grid mr={1} xs={9} sm={10}>
                            <Typography sx={{ fontSize: { xs: 14, sm: 'initial' } }}>
                                {'Megértettem és elfogadom az '}
                                <MuiLink sx={{ cursor: 'pointer' }} onClick={() => setOpenASZF(true)} target={'aszf'}>
                                    {'ÁSZF'}
                                </MuiLink>
                                {'-ben és az '}
                                <MuiLink sx={{ cursor: 'pointer' }} onClick={() => setOpenPrivacyPolicy(true)} target={'privpol'}>
                                    {'Adatvédelmi tájékoztatóban '}
                                </MuiLink>
                                {'foglaltakat.'}
                            </Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid alignItems={'center'} container paddingY={1} xs={12}>
                        <Grid xs={2} sm={1}>
                            <Checkbox
                                checked={termsAndConditionsAccepted}
                                onChange={() => setTermsAndConditionsAccepted((current) => !current)}
                                value={termsAndConditionsAccepted}
                            />
                        </Grid>
                        <Grid mr={1} xs={9} sm={10}>
                            <Typography sx={{ fontSize: { xs: 14, sm: 'initial' } }}>
                                {'I have read and accepted the '}
                                <MuiLink sx={{ cursor: 'pointer' }} onClick={() => setOpenASZF(true)} target={'aszf'}>
                                    {'terms and conditions '}
                                </MuiLink>
                                {'and the '}
                                <MuiLink sx={{ cursor: 'pointer' }} onClick={() => setOpenPrivacyPolicy(true)} target={'privpol'}>
                                    {'Privacy Policy.'}
                                </MuiLink>
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                <Grid paddingY={1} xs={12}>
                    <LoadingButton
                        className="bcolor"
                        // disabled={submitDisabled}
                        loading={navigation.state === 'submitting'}
                        sx={{ color: 'black' }}
                    >
                        {t('registration')}
                    </LoadingButton>
                </Grid>
            </Form>
            <Grid xs={12}>
                <Grid alignItems={'center'} container justifyContent={'center'}>
                    <Box
                        sx={{
                            display: 'flex',
                            width: 1,
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography>{t('already_have_an_account')}</Typography>
                        <Link to={'/authentication/login'}>
                            <Typography sx={{ color: 'teal', fontWeight: 600, fontSize: 15 }}>{t('login')}</Typography>
                        </Link>
                    </Box>
                    {/* <Button sx={{ p: 0 }}>
                        <Link sx={{ width: 1 }} to={'/authentication/login'}>
                            {t('button_text_back')}
                        </Link>
                    </Button> */}
                </Grid>
            </Grid>
            <Grid pt={1} xs={12} sx={{ m: '0 auto', textAlign: 'center' }}>
                <Link sx={{ width: 1, p: 0, color: 'black', display: 'flex' }} to={'/authentication/login'}>
                    <ArrowBackIcon sx={{ width: '20px', mr: 1 }} /> {t('button_text_back')}
                </Link>
            </Grid>
        </Fragment>
    );
}
