import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { Select, MenuItem, FormControl, InputLabel, TextField } from '@mui/material';
import { Table, TableBody, TableRow, TableCell, TableHead, Dialog, DialogContent } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import HelpIcon from '@mui/icons-material/Help';
import { IconButton } from '@mui/material';
import '@inovua/reactdatagrid-community/index.css';
import { axios } from '../utilities/api';
import QuestionsDraggable from './QuestionsDraggable';
import useSnackbar from '../hooks/useSnackbar';
const sampleData = [
    ['S', 'A', 'M', 'P', 'L', 'E', 'Total'],
    ['?', '?', '?', '?', '?', '?', '?'],
    ['?', '?', '?', '?', '?', '?', '?'],
    ['?', '?', '?', '?', '?', '?', '?']
];

export default function CrosstableAnalysis() {
    const { t } = useTranslation();
    const [questionColumn, setQuestionColumn] = useState(null);
    const [questionRow, setQuestionRow] = useState(null);
    const [crossTableData, setCrossTableData] = useState(sampleData);
    const [chiSignificance, setChiSignicifance] = useState(0.05);
    const [chiCalculation, setChiCalculation] = useState('-');
    const [chiResult, setChiResult] = useState({});
    const { questions, user } = useOutletContext();
    const [infoDialog, setInfoDialog] = useState(null);
    const { setWarning } = useSnackbar();
    async function calculate() {
        await getCrossTable();
        await chi2Calc();
    }

    useEffect(() => {
        if (questionColumn && questionRow) {
            calculate();
        }
        setCrossTableData(sampleData);
    }, [questionColumn, questionRow]);

    async function getCrossTable() {
        const data = { question1: questionRow?.split('_')?.[0], question2: questionColumn?.split('_')?.[0] };
        try {
            const response = await axios.post(`analysis/get-crosstable/`, data);

            setCrossTableData(response.data.data);
            setChiResult({});
        } catch (error) {
            setWarning(t('query_not_successful'));
            throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 833 };
        }
    }

    async function chi2Calc() {
        const data = {
            question1: questionRow?.split('_')?.[0],
            question2: questionColumn?.split('_')?.[0],
            significance: chiSignificance,
            calculation: chiCalculation
        };

        try {
            const response = await axios.post(`analysis/get-chi-stats/`, data);
            setChiResult(response?.data);
        } catch (error) {
            throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 834 };
        }
    }

    return (
        <>
            <Dialog open={infoDialog}>
                <DialogContent>
                    <Grid container justifyContent={'center'}>
                        <Typography align="center">{infoDialog}</Typography>
                    </Grid>
                    <Grid container justifyContent={'center'} pt={2}>
                        <Button sx={{ width: 'auto' }} onClick={() => setInfoDialog(false)}>
                            {t('close')}
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Grid container justifyContent={'center'} sx={{ textAlign: 'center', width: 1 }} py={1}>
                <Typography variant={'h5'}>
                    {t('crosstable_analysis')}
                    {user?.details ? (
                        <Grid container justifyContent={'center'}>
                            <Typography variant={'body2'} align="center">
                                {t('crosstable_help_text')}
                            </Typography>
                        </Grid>
                    ) : (
                        <IconButton onClick={() => setInfoDialog(t('crosstable_help_text'))}>
                            <HelpIcon />
                        </IconButton>
                    )}
                </Typography>
            </Grid>
            <DragDropContext
                onDragEnd={(event) => {
                    if (event.source.droppableId === 'questions' && event.destination?.droppableId === 'questions') return;
                    if (event.source.droppableId === 'questionColumn' && event.destination?.droppableId === 'questionColumn') return;
                    if (event.source.droppableId === 'questionRow' && event.destination?.droppableId === 'questionRow') return;

                    if (event.destination.droppableId === 'questionRow' && event.draggableId === questionColumn) setQuestionColumn(null);
                    if (event.destination.droppableId === 'questionColumn' && event.draggableId === questionRow) setQuestionRow(null);

                    if (event.source.droppableId === 'questions' && event.destination?.droppableId === 'questionColumn') {
                        setQuestionColumn(event.draggableId);
                    }

                    if (event.source.droppableId === 'questions' && event.destination?.droppableId === 'questionRow') {
                        setQuestionRow(event.draggableId);
                    }
                }}
            >
                <Grid container justifyContent={'center'} sx={{ textAlign: 'center', width: 1 }}>
                    <Grid xs={12} py={2}>
                        <QuestionsDraggable questions={questions} />
                    </Grid>
                    <Grid xs={12} py={2}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align={'center'} colSpan={crossTableData?.[0]?.length + 1 ?? 1} sx={{ fontWeight: 'bold' }}>
                                        <Droppable droppableId="questionColumn">
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    style={{
                                                        backgroundColor: snapshot.isDraggingOver ? '#4C9F93' : '#cbe6e2',
                                                        width: '100%',
                                                        height: '100%'
                                                    }}
                                                    {...provided.droppableProps}
                                                >
                                                    {questionColumn?.substring(questionColumn?.indexOf('_') + 1) ?? t('drag_desired_question')}
                                                    {provided.placeholder}
                                                    {' (B)'}
                                                </div>
                                            )}
                                        </Droppable>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {crossTableData?.map((row, index) => {
                                    return index === 0 ? (
                                        <TableRow key={index}>
                                            <TableCell
                                                rowSpan={crossTableData?.length}
                                                sx={{
                                                    transform: 'rotate(-90deg)',
                                                    width: `${crossTableData?.length * 50}px`,
                                                    padding: 0,
                                                    fontWeight: 'bold'
                                                }}
                                                align="center"
                                            >
                                                <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                                    <Droppable droppableId="questionRow">
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                style={{
                                                                    backgroundColor: snapshot.isDraggingOver ? '#4C9F93' : '#cbe6e2',
                                                                    width: '100%',
                                                                    height: '100%'
                                                                }}
                                                                {...provided.droppableProps}
                                                            >
                                                                {questionRow?.substring(questionRow?.indexOf('_') + 1) ?? t('drag_desired_question')}
                                                                {provided.placeholder}
                                                                {' (A)'}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </div>
                                            </TableCell>
                                            {row?.map((item, inner_index) => (
                                                <TableCell
                                                    key={inner_index}
                                                    sx={{
                                                        fontWeight:
                                                            index === crossTableData?.length - 1 || inner_index === crossTableData?.[index]?.length - 1
                                                                ? 'bold'
                                                                : 'normal'
                                                    }}
                                                >
                                                    {item}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ) : (
                                        <TableRow key={index}>
                                            {row?.map((item, inner_index) => {
                                                return (
                                                    <TableCell
                                                        key={inner_index}
                                                        sx={{
                                                            fontWeight:
                                                                index === crossTableData?.length - 1 || inner_index === crossTableData?.[index]?.length - 1
                                                                    ? 'bold'
                                                                    : 'normal'
                                                        }}
                                                    >
                                                        {item}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Grid>
                    {Object.keys(chiResult)?.length ? (
                        <Grid xs={12} py={1}>
                            <Grid xs={12}>
                                <Typography align="center">
                                    &#11436;<sup>2</sup>
                                    {' ' + t('chi_test_p')}
                                    {user?.details ? (
                                        <Grid container justifyContent={'center'}>
                                            <Typography variant={'body2'} align="center">
                                                {t('chi_help_text')}
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <IconButton onClick={() => setInfoDialog(t('chi_help_text'))}>
                                            <HelpIcon />
                                        </IconButton>
                                    )}
                                </Typography>
                            </Grid>
                            <Typography align="center">
                                &#11436;<sup>2</sup>
                                {` = ${chiResult?.chi_result ?? ''}`}
                            </Typography>
                            <Typography align="center">{`${t('chi_table_value')}: ${chiResult?.chi_table_value} (${chiResult?.chi_significance})`}</Typography>
                            <Typography align="center">
                                {chiResult?.chi_table_value < chiResult?.chi_result ? t('correlation_between_data') : t('no_correlation_between_data')}
                            </Typography>
                            <Grid container justifyContent={'center'} py={1}>
                                <FormControl component="fieldset">
                                    <Grid container alignItems="center" justifyContent={'center'} spacing={1}>
                                        <Grid item>
                                            <TextField
                                                type="number"
                                                sx={{ width: '300px' }}
                                                inputProps={{ min: 0.005, max: 0.995, step: 0.005 }}
                                                label={t('significance_level')}
                                                value={chiSignificance}
                                                onChange={(e) => setChiSignicifance(e.target.value)}
                                            />
                                            {user?.details ? (
                                                <Typography variant="body2" align="center">
                                                    {t('significance_help_text')}
                                                </Typography>
                                            ) : (
                                                <IconButton onClick={() => setInfoDialog(t('significance_help_text'))}>
                                                    <HelpIcon />
                                                </IconButton>
                                            )}
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </Grid>
                            <Grid container justifyContent={'center'} py={2}>
                                {t('recommended_test')}:{' '}
                                {chiResult?.recommended_test?.map((item, index) => {
                                    return `${t(item)}${index != chiResult?.recommended_test?.length - 1 ? ', ' : ''}`;
                                })}
                            </Grid>
                            <Grid xs={12}>
                                <Grid container py={1}>
                                    <FormControl>
                                        <InputLabel>{t('correlation_calc')}</InputLabel>
                                        <Select value={chiCalculation ?? ''} onChange={(e) => setChiCalculation(e.target.value)} label={t('correlation_calc')}>
                                            <MenuItem key={'-'} value={'-'}>
                                                {t('-')}
                                            </MenuItem>
                                            <MenuItem key={'cramer_v'} value={'cramer_v'}>
                                                {t('cramer_v')}
                                            </MenuItem>
                                            <MenuItem key={'kendall_tau'} value={'kendall_tau'}>
                                                {t('kendall_tau')}
                                            </MenuItem>
                                            <MenuItem key={'contingency_coefficient'} value={'contingency_coefficient'}>
                                                {t('contingency_coefficient')}
                                            </MenuItem>
                                            <MenuItem key={'phi_coefficient'} value={'phi_coefficient'}>
                                                {t('phi_coefficient')}
                                            </MenuItem>
                                            <MenuItem key={'lambda'} value={'lambda'}>
                                                {t('lambda')}
                                            </MenuItem>
                                            <MenuItem key={'goodman_kruskal_tau'} value={'goodman_kruskal_tau'}>
                                                {t('goodman_kruskal_tau')}
                                            </MenuItem>
                                            <MenuItem key={'goodman_kruskal_gamma'} value={'goodman_kruskal_gamma'}>
                                                {t('goodman_kruskal_gamma')}
                                            </MenuItem>
                                            <MenuItem key={'uncertainty_coefficient'} value={'uncertainty_coefficient'}>
                                                {t('uncertainty_coefficient')}
                                            </MenuItem>
                                            <MenuItem key={'somers_d'} value={'somers_d'}>
                                                {t('somers_d')}
                                            </MenuItem>
                                            <MenuItem key={'kendall_tau_b'} value={'kendall_tau_b'}>
                                                {t('kendall_tau_b')}
                                            </MenuItem>
                                            <MenuItem key={'kendall_tau_c'} value={'kendall_tau_c'}>
                                                {t('kendall_tau_c')}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid xs={12}>
                                <Grid container justifyContent={'center'}>
                                    <Button disabled={!questionColumn || !questionRow} variant={'contained'} onClick={chi2Calc}>
                                        {t('chi2_calc')}
                                    </Button>
                                </Grid>
                            </Grid>
                            {chiResult !== '' && (
                                <>
                                    <Grid xs={12}>
                                        <Typography align="center">{`${t('calculation_type')} : ${t(chiResult?.calculation_type ?? '')}`}</Typography>
                                        <Typography align="center">{`${t('calculation_result')} ${
                                            chiResult?.calculation_type == 'lambda' ? 'lambda(B|A)' : ''
                                        } : ${chiResult?.calculation_result ?? ''}`}</Typography>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    ) : null}
                </Grid>
            </DragDropContext>
        </>
    );
}
