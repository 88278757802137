import { useState } from 'react';
import { useOutletContext, Outlet, useNavigate, useNavigation } from 'react-router-dom';
import { AppBar, MenuItem, Divider, Menu, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AnalysisButton from '../components/AnalysisButton';

import bar_icon from '../../src/assets/icons/bar_colored.png';
import anova_icon from '../../src/assets/icons/anova_colored.png';
import cluster_icon from '../../src/assets/icons/cluster_colored.png';
import discriminant_icon from '../../src/assets/icons/classification_colored.png';
import crosstable_icon from '../../src/assets/icons/crosstable_colored.png';
import regression_icon from '../../src/assets/icons/regression_colored.png';

const LargeIconSize = 40;
const SmallIconSize = 22;

const BarChartIcon = () => <img src={bar_icon} alt="Barchart" style={{ width: `${LargeIconSize}px`, height: `${LargeIconSize}px` }} />;
const AnovaIcon = () => <img src={anova_icon} alt="Anova" style={{ width: `${LargeIconSize}px`, height: `${LargeIconSize}px` }} />;
const ClusterIcon = () => <img src={cluster_icon} alt="Cluster" style={{ width: `${LargeIconSize}px`, height: `${LargeIconSize}px` }} />;
const DiscriminantIcon = () => <img src={discriminant_icon} alt="Discriminant" style={{ width: `${LargeIconSize}px`, height: `${LargeIconSize}px` }} />;
const CrosstableIcon = () => <img src={crosstable_icon} alt="Crosstable" style={{ width: `${LargeIconSize}px`, height: `${LargeIconSize}px` }} />;
const RegressionIcon = () => <img src={regression_icon} alt="Regression" style={{ width: `${LargeIconSize}px`, height: `${LargeIconSize}px` }} />;

export default function SurveyTableLayout() {
    const { analysisType, sidebarWidth, user } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const [menuChoice, setMenuChoice] = useState('');

    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        setAnchorEl(null);
    };

    const menuItems = {
        'discriminant-analysis': [
            { path: 'discriminant-analysis/preconditions', text: t('discriminant_analysis_preconditions') }
            // { path: 'discriminant-analysis/multicollinearity', text: t('discriminant_analysis_multicollinearity'), pl: 5 }
        ]
    };

    return (
        <Grid xs={12}>
            <Grid container justifyContent={'center'}>
                <AppBar position="sticky" sx={{ background: 'linear-gradient(to right, rgb(182, 244, 146), rgb(51, 139, 147))', height: '72px' }} elevation={1}>
                    <Toolbar style={{ padding: 0 }}>
                        <Menu id="basic-menu" anchorEl={anchorEl} open={Boolean(open)} onClose={handleClose}>
                            {menuItems?.[menuChoice]?.map((item, index) => {
                                return (
                                    <>
                                        <MenuItem key={index} sx={{ paddingLeft: item?.pl ? 5 : 1 }} onClick={() => navigate(`table/${item.path}`)}>
                                            {item.text}
                                        </MenuItem>
                                        <Divider />
                                    </>
                                );
                            })}
                        </Menu>

                        <Divider orientation="vertical" flexItem />
                        <AnalysisButton
                            active={location.pathname.includes('descriptive')}
                            onClick={() => navigate('table/descriptive')}
                            text={t('descriptives')}
                            Icon={BarChartIcon}
                        />
                        <Divider orientation="vertical" flexItem />
                        <AnalysisButton
                            active={location.pathname.includes('crosstable-analysis')}
                            onClick={() => navigate('table/crosstable-analysis')}
                            text={t('crosstable_analysis')}
                            Icon={CrosstableIcon}
                        />
                        <Divider orientation="vertical" flexItem />
                        <AnalysisButton
                            active={location.pathname.includes('discriminant-analysis')}
                            onClick={(event) => {
                                setMenuChoice('discriminant-analysis');
                                handleMenu(event);
                            }}
                            text={t('discriminant_analysis')}
                            Icon={DiscriminantIcon}
                        />
                        <Divider orientation="vertical" flexItem />
                        <AnalysisButton
                            active={location.pathname.includes('anova')}
                            onClick={() => navigate('table/anova')}
                            text={t('anova')}
                            Icon={AnovaIcon}
                        />
                        <Divider orientation="vertical" flexItem />
                        <AnalysisButton
                            active={location.pathname.includes('regression')}
                            onClick={() => navigate('table/regression')}
                            text={t('regression')}
                            Icon={RegressionIcon}
                        />
                        <Divider orientation="vertical" flexItem />
                        <AnalysisButton
                            active={location.pathname.includes('clustering')}
                            onClick={() => navigate('table/clustering')}
                            text={t('clustering')}
                            Icon={ClusterIcon}
                        />
                        <Divider orientation="vertical" flexItem />
                    </Toolbar>
                </AppBar>
                <Outlet context={{ analysisType, sidebarWidth, user }} />
            </Grid>
        </Grid>
    );
}
