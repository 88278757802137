import { redirect } from 'react-router-dom';

import {
    login,
    registration,
    newPassword,
    uploadFile,
    renameDocument,
    scaleTypeChange,
    resetPassword,
    updateUser,
    newPasswordWithConfirmAction,
    editHeaderAction
} from './api';

export async function loginAction({ request }) {
    const formData = await request.formData();
    const data = { email: formData.get('email'), password: formData.get('password') };
    return await login(data);
}

export async function registrationAction({ request }) {
    const formData = await request.formData();
    const data = {
        email: formData.get('email'),
        first_name: formData.get('firstName'),
        last_name: formData.get('lastName'),
        language: formData.get('language'),
        password: formData.get('password')
    };
    return await registration(data);
}

export async function newPasswordAction({ params, request }) {
    const formData = await request.formData();
    const data = { password: formData.get('password'), token: formData.get('token') };
    return await newPassword(data);
}

export async function resetPasswordAction({ request }) {
    const formData = await request.formData();
    const data = { email: formData.get('email') };
    return await resetPassword(data);
}

export async function uploadFileAction({ request }) {
    let data = new FormData();
    const formData = await request.formData();
    data.append('file', formData.get('file'));
    data.append('is_voter', formData.get('isVoter'));
    return await uploadFile(data);
}

export async function documentModifyAction({ params, request }) {
    const formData = await request.formData();
    return await renameDocument({ name: formData.get('name') }, params?.id);
}

export async function scaleTypeChangeAction({ params, request }) {
    const formData = await request.formData();
    const data = { scale_type: formData.get('type') };
    return await scaleTypeChange(data, formData.get('id'));
}

export async function updateUserAction({ request }) {
    const formData = await request.formData();
    return await updateUser(formData);
}

export async function newPasswordWithConfirm({ request }) {
    const formData = await request.formData();
    return await newPasswordWithConfirmAction(formData);
}

export async function editHeader({ request }) {
    const data = Object.fromEntries(await request.formData());
    return await editHeaderAction(data);
}
