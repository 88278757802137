import { useState, React } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button, IconButton, Switch, Dialog, DialogContent } from '@mui/material';
import { useOutletContext } from 'react-router';
import { useTranslation } from 'react-i18next';
import { axios } from '../utilities/api';
import { Droppable, DragDropContext, Draggable } from '@hello-pangea/dnd';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import QuestionsDraggable from '../components/QuestionsDraggable';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Plot from 'react-plotly.js';
import HelpIcon from '@mui/icons-material/Help';
import error_icon from '../assets/icons/error.png';
import correct_icon from '../assets/icons/correct.png';
import warning_icon from '../assets/icons/warning.png';

const ErrorIcon = () => <img src={error_icon} alt="error" width="25" height="25" />;
const CorrectIcon = () => <img src={correct_icon} alt="correct" width="25" height="25" />;
const WarningIcon = () => <img src={warning_icon} alt="warning" width="25" height="25" />;

export default function DAPreconditionTest() {
    const { questions, user } = useOutletContext();
    const { t } = useTranslation();
    const [selectedX, setSelectedX] = useState([]);
    const [selectedY, setSelectedY] = useState();
    const [data, setData] = useState(null);
    const [allConditionsMet, setAllConditionsMet] = useState(true);
    const [infoDialog, setInfoDialog] = useState(false);
    const [preconditionsFailedDialog, setPreconditionsFailedDialog] = useState(false);

    async function loadPreCheckData() {
        try {
            let copy = structuredClone(selectedX);
            const data = { question_x: copy?.map((item) => item?.split('_')[0]), question_y: selectedY?.split('_')[0] };
            const response = await axios.post('analysis/anova-pre-conditions/', data);
            console.log(response);
            Object.entries(response.data).forEach(element => {
                if (element[1] === false) setAllConditionsMet(false);
            });
            setData(response.data);
        } catch (error) {
            console.log(error);
            throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 833 };
        }
    }

    function loadEmblem(value) {
        if (value === null) return <WarningIcon />;
        else if (value) return <CorrectIcon />;
        else if (!value) return <ErrorIcon />;
    }

    function performANOVA() {
        alert('ANOVA');
    }
    // Fuggetlenek nominalisal
    // Fuggo valtozok metrikusak

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h5" align="center">
                    {t('ANOVA')}
                    {!user?.details && (
                        <IconButton onClick={() => setInfoDialog(t('da_help_text'))}>
                            <HelpIcon />
                        </IconButton>
                    )}
                </Typography>
                {user?.details && (
                    <Grid container justifyContent={'center'}>
                        <Typography variant={'body2'} align="center">
                            {t('da_help_text')}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Dialog open={infoDialog}>
                <DialogContent>
                    <Grid container justifyContent={'center'}>
                        <Typography align="center">{infoDialog}</Typography>
                    </Grid>
                    <Grid container justifyContent={'center'} pt={2}>
                        <Button sx={{ width: 'auto' }} onClick={() => setInfoDialog(false)}>
                            {t('close')}
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Dialog open={preconditionsFailedDialog}>
                <DialogContent>
                    <Grid container justifyContent={'center'}>
                        <Typography align="center">{t('preconditions_failed')}</Typography>
                    </Grid>
                    <Grid container justifyContent={'space-between'} pt={2}>
                        <Button sx={{ width: 'auto', backgroundColor: 'red' }} onClick={() => setPreconditionsFailedDialog(false)}>
                            {t('close')}
                        </Button>
                        <Button sx={{ width: 'auto' }} onClick={() => {performANOVA(); setPreconditionsFailedDialog(false)}}>
                            {t('perform_anova')}
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
            <DragDropContext
                onDragEnd={(event) => {
                    if (event.source.droppableId === event.destination?.droppableId) return;

                    if (event.source.droppableId === 'questions' && event.destination?.droppableId === 'selectedX') {
                        if (selectedX?.includes(event.draggableId)) return;
                        let temp = [...selectedX];
                        temp.push(event.draggableId);
                        setSelectedX(temp);
                    }

                    if (event.source.droppableId === 'questions' && event.destination?.droppableId === 'selectedY') {
                        setSelectedY(event.draggableId);
                    }
                }}
            >
                <Grid container>
                    <Grid xs={12} py={2}>
                        <QuestionsDraggable questions={questions} />
                    </Grid>
                    {user?.details && (
                        <Typography variant="body2" align="center">
                            {t('dep_indep_help_text')}
                        </Typography>
                    )}

                    <Grid xs={12} pt={2}>
                        <Typography variant="body1">
                            {t('discriminant_x')}{' '}
                            {!user?.details && (
                                <IconButton onClick={() => setInfoDialog(t('dep_indep_help_text'))}>
                                    <HelpIcon />
                                </IconButton>
                            )}
                        </Typography>
                        <Droppable droppableId="selectedX">
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    style={{
                                        backgroundColor: snapshot.isDraggingOver ? '#4C9F93' : '#cbe6e2',
                                        width: '100%',
                                        height: '100%'
                                    }}
                                    {...provided.droppableProps}
                                >
                                    {selectedX?.map((question, index) => {
                                        return (
                                            <Grid key={index} container justifyContent="center">
                                                <Grid xs={10}>{question?.substring(question?.indexOf('_') + 1)}</Grid>
                                                <Grid xs={2}>
                                                    <IconButton
                                                        onClick={() => {
                                                            let temp = [...selectedX];
                                                            temp.splice(index, 1);
                                                            setSelectedX(temp);
                                                        }}
                                                    >
                                                        <HighlightOffIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </Grid>
                    <Grid xs={12} py={6}>
                        <Typography variant="body1">
                            {t('discriminant_y')}
                            {!user?.details && (
                                <IconButton onClick={() => setInfoDialog(t('dep_indep_help_text'))}>
                                    <HelpIcon />
                                </IconButton>
                            )}
                        </Typography>
                        <Droppable droppableId="selectedY">
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    style={{
                                        backgroundColor: snapshot.isDraggingOver ? '#4C9F93' : '#cbe6e2',
                                        width: '100%',
                                        height: '100%'
                                    }}
                                    {...provided.droppableProps}
                                >
                                    {selectedY && (
                                        <Grid container>
                                            <Grid xs={10}> {selectedY?.substring(selectedY?.indexOf('_') + 1) ?? t('selectedy')}</Grid>
                                            <Grid xs={2}>
                                                <IconButton
                                                    onClick={() => {
                                                        setSelectedY(undefined);
                                                    }}
                                                >
                                                    <HighlightOffIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    )}

                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'center'} pt={3}>
                <Button disabled={!selectedX?.length || !selectedY} onClick={() => loadPreCheckData()} >
                    {t('check')}
                </Button>
                </Grid>
                {data && (
                    <Grid container justifyContent={'flex-start'} pt={5}>
                        <Grid xs={12} py={1}>
                            <Grid container alignItems={'center'}>
                                <Typography variant={'body1'} pr={1}>
                                    {t('measurement_level_of_variables')}
                                </Typography>
                                {loadEmblem(data?.['independent_variables_nominal'] && data?.['dependent_variable_metric'])}
                            </Grid>
                            <Grid pl={4} container alignItems={'center'}>
                                <Typography variant={'body2'} pr={1}>
                                    {t('independent_variables_nominal')}
                                </Typography>
                                {loadEmblem(data?.['independent_variables_nominal'])}
                            </Grid>
                            <Grid pl={4} container alignItems={'center'}>
                                <Typography variant={'body2'} pr={1}>
                                    {t('dependent_variable_metric')}
                                </Typography>
                                {loadEmblem(data?.['dependent_variable_metric'])}
                            </Grid>
                        </Grid>
                        <Grid xs={12} py={1}>
                            <Grid container alignItems={'center'}>
                                <Typography variant={'body1'} pr={1}>
                                    {t('dependent_variable_normal')}
                                </Typography>
                                {loadEmblem(data?.['dependent_variable_normal'])}
                            </Grid>
                            <Grid pl={4} container alignItems={'center'}>
                                <Typography variant={'body2'} pr={1}>
                                    {t('dependent_variable_normal_desc')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid xs={12} py={1}>
                            <Grid container alignItems={'center'}>
                                <Typography variant={'body1'} pr={1}>
                                    {t('variance_homogeneity', { serialNumber: 3 })}
                                </Typography>
                                {loadEmblem(data?.['variance_homogeneity'])}
                            </Grid>
                            <Grid pl={4} container alignItems={'center'}>
                                <Typography variant={'body2'} pr={1}>
                                    {t('variance_homogeneity_desc')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid xs={12} py={1}>
                            <Grid container alignItems={'center'}>
                                <Button
                                    onClick={() => {
                                        if (allConditionsMet) performANOVA()
                                        else setPreconditionsFailedDialog(true)
                                    }}
                                >
                                    {t('perform_anova')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </DragDropContext>
        </>
    );
}
