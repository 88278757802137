import { createContext, useState } from 'react';

const SnackbarContext = createContext({});

const severities = {
    ERROR: 'error',
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
};

export function SnackbarProvider({ children }) {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState(severities.SUCCESS);

    function setError(message = 'Error!') {
        setMessage(message);
        setSeverity(severities.ERROR);
        setOpen(true);
    }

    function setSuccess(message = 'Success!') {
        setMessage(message);
        setSeverity(severities.SUCCESS);
        setOpen(true);
    }

    function setWarning(message = 'Warning!') {
        setMessage(message);
        setSeverity(severities.WARNING);
        setOpen(true);
    }

    return (
        <SnackbarContext.Provider value={{ message, open, setError, setOpen, setSuccess, setWarning, severity }}>
            {children}
        </SnackbarContext.Provider>
    );
}

export default SnackbarContext;
