import { Typography } from '@mui/material';
import React from 'react';
import Plot from 'react-plotly.js';
import { colors } from '../assets/colors';
import { chartLabelShort } from '../utilities/functions';

export default function SelectPlotType(props) {
    const Plots = {
        bar: (
            <Plot
                data={[
                    {
                        type: 'bar',
                        text: props.data?.frequencies?.map((answer, idx) => answer.count),
                        textposition: 'auto',
                        x: props.data?.frequencies?.map((answer, idx) => chartLabelShort(answer.unique_answer__value)),
                        // x: props.data?.frequencies?.map((answer, idx) => answer.unique_answer__value),
                        y: props.data?.frequencies?.map((answer, idx) => answer.count),
                        hoverinfo: 'y+text',
                        hovertext: props.data?.frequencies?.map((answer, idx) => answer.unique_answer__value),
                        // labelalias:
                        marker: {
                            color: colors
                        }
                    }
                ]}
                layout={{
                    xaxis: {
                        type: 'category' // Force x-axis values as category (string)
                    }
                }}
            />
        ),
        line: (
            <Plot
                data={[
                    {
                        type: 'bar',
                        text: props.data?.frequencies?.map((answer, idx) => answer.count),
                        textposition: 'auto',
                        y: props.data?.frequencies?.map((answer, idx) => chartLabelShort(answer.unique_answer__value)),
                        // x: props.data?.frequencies?.map((answer, idx) => answer.unique_answer__value),
                        x: props.data?.frequencies?.map((answer, idx) => answer.count),
                        hoverinfo: 'x+text',
                        hovertext: props.data?.frequencies?.map((answer, idx) => answer.unique_answer__value),
                        // labelalias:
                        marker: {
                            color: colors
                        },
                        orientation: 'h'
                    }
                ]}
                layout={{
                    yaxis: {
                        type: 'category' // Force x-axis values as category (string)
                    }
                }}
            />
        ),
        pie: (
            <Plot
                data={[
                    {
                        type: 'pie',
                        text: props.data?.frequencies?.map((answer, idx) => answer.count),
                        labels: props.data?.frequencies?.map((answer, idx) => chartLabelShort(answer.unique_answer__value)),
                        values: props.data?.frequencies?.map((answer, idx) => answer.count),
                        hoverinfo: 'y+text',
                        hovertext: props.data?.frequencies?.map((answer, idx) => answer.unique_answer__value),
                        marker: {
                            colors: colors
                        }
                    }
                ]}
            />
        )
    };
    return props.selectedChart ? Plots[props.selectedChart] : <></>;
}
