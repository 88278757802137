import React, { useEffect } from 'react';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { Form, useActionData, useLocation, useNavigate, useNavigation, useSubmit, useOutletContext, useFetcher } from 'react-router-dom';
import { Paper, TextField, Select, MenuItem, Checkbox, FormControl, InputLabel, Switch } from '@mui/material';
import analysis from '../assets/icons/analysis.png';
import { Typography, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import useSnackbar from '../hooks/useSnackbar';
import Link from '../components/Link';
export default function Profile(props) {
    const { t } = useTranslation();
    const fetcher = useFetcher();
    const { pathname, state } = useLocation();
    const { user } = useOutletContext();
    const { setSuccess, setWarning } = useSnackbar();
    const response = useActionData();
    const navigate = useNavigate();

    useEffect(() => {
        if (response?.status === 200) {
            setSuccess(t('user_data_modified_success'));
            // navigate('/dashboard/profile');
        }
    }, [response]);
    return (
        <Grid xs={12} md={6} p={1}>
            <Paper elevation={8}>
                <Form action={pathname} method="post">
                    <Grid container p={1} spacing={1} justifyContent={'center'}>
                        <Grid xs={12}>
                            <Grid container justifyContent={'center'}>
                                <Grid xs={1}>
                                    <img src={analysis} height={40} />
                                </Grid>
                                <Grid xs>
                                    <Typography align={'center'} variant={'h5'} sx={{ display: 'block' }}>
                                        {t('profile')}
                                    </Typography>
                                </Grid>
                                <Grid xs={1}></Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12}>
                            <TextField fullWidth label={t('email_address')} disabled value={user?.email} />
                        </Grid>
                        <Grid xs={12} md={6}>
                            <TextField name="last_name" fullWidth label={t('last_name')} defaultValue={user?.last_name} autoComplete={'off'} />
                        </Grid>
                        <Grid xs={12} md={6}>
                            <TextField name="first_name" fullWidth label={t('first_name')} defaultValue={user?.first_name} autoComplete={'off'} />
                        </Grid>

                        <Grid xs={12} md={6}>
                            <FormControl>
                                <InputLabel>{t('preferred_chart')}</InputLabel>
                                <Select label={t('preferred_chart')} name="preferred_chart" defaultValue={user?.preferred_chart} fullWidth>
                                    <MenuItem value={'line'}>{t('pref_chart_line')}</MenuItem>
                                    <MenuItem value={'bar'}>{t('pref_chart_bar')}</MenuItem>
                                    <MenuItem value={'pie'}>{t('pref_chart_pie')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <InputLabel>{t('language')}</InputLabel>
                                <Select label={t('language')} name="language" defaultValue={user?.language} fullWidth>
                                    <MenuItem value={'en'}>{t('language_en')}</MenuItem>
                                    {/* <MenuItem value={'en_us'}>{t('language_en_us')}</MenuItem> */}
                                    <MenuItem value={'hu'}>{t('language_hu')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12}>
                            {/* <FormControlLabel control={<Checkbox name="details" defaultChecked={user.details} />} label={t('details')} /> */}
                            <Typography align="center" variant="h6">
                                {t('help_text')}
                            </Typography>
                            <Typography align="center">
                                {t('in_tooltip')}
                                <Switch name="details" defaultChecked={user.details} />
                                {t('on_screen')}
                            </Typography>
                        </Grid>
                        <Grid xs={12}>
                            <Typography align={'center'} variant={'h5'} sx={{ display: 'block' }}>
                                {t('billing_information')}
                            </Typography>
                        </Grid>
                        <Grid xs={12}>
                            <TextField label={t('zipcode')} name={'zipcode'} defaultValue={user.zipcode} autoComplete={'off'} />
                        </Grid>
                        <Grid xs={12}>
                            <TextField label={t('settlement')} name={'settlement'} defaultValue={user.settlement} autoComplete={'off'} />
                        </Grid>
                        <Grid xs={12}>
                            <TextField label={t('address')} name={'address'} defaultValue={user.address} autoComplete={'off'} />
                        </Grid>
                        <Grid xs={12}>
                            <Link to={'/passwordchange'}>{t('password_change')}</Link>
                        </Grid>
                        <Grid paddingY={1} xs={12}>
                            <LoadingButton
                                className="bcolor"
                                // disabled={!email?.length || !password?.length}
                                // loading={navigation.state === 'submitting'}
                                sx={{ color: 'black' }}
                            >
                                {t('save')}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Form>
            </Paper>
        </Grid>
    );
}
