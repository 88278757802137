import { useState, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Select, MenuItem, FormControl, InputLabel, Divider, TextField, Button, TableContainer } from '@mui/material';
import { Table, TableBody, TableRow, TableCell, TableHead } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { axios } from '../utilities/api';
import Plot from 'react-plotly.js';
import { colors } from '../assets/colors';
import { chartLabelShort } from '../utilities/functions';
import barChart from '../assets/icons/bar-chart.png';
import barGraph from '../assets/icons/bar-graph.png';
import pieChart from '../assets/icons/pie-chart.png';
import useSnackBar from '../hooks/useSnackbar';
import SelectPlotType from './SelectPlotType';
export default function DescriptiveStatistics() {
    const { t } = useTranslation();
    const { questions, user } = useOutletContext();
    const { setWarning } = useSnackBar();
    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [descriptiveStatistics, setDescriptiveStatistics] = useState({});
    const [selectedChart, setSelectedChart] = useState(user?.preferred_chart);
    async function getStatistics(header) {
        try {
            const response = await axios.get(`analysis/get-descriptive-statistics/${header}`);
            setDescriptiveStatistics(response.data);
        } catch (error) {
            console.log(error);
            setWarning(t('query_not_successful'));
            throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 832 };
        }
    }

    const charts = [
        { alt: 'bar', src: barChart },
        { alt: 'line', src: barGraph },
        { alt: 'pie', src: pieChart }
    ];

    const selectChart = (e) => {
        setSelectedChart(e.target.alt);
    };

    return (
        <Grid container>
            <Grid xs={12} py={1}>
                <Typography variant="h5" align="center">
                    {t('descriptive_statistics')}
                </Typography>
            </Grid>
            <Grid xs={12}>
                <FormControl>
                    <InputLabel>{`${t('question')}`}</InputLabel>
                    <Select
                        value={selectedQuestion}
                        label="Kérdés"
                        onChange={(e) => {
                            setSelectedQuestion(e.target.value);
                            getStatistics(e.target?.value?.split('_')[0]);
                        }}
                    >
                        {Object.keys(questions?.[0] ?? {})?.map((question, index) => {
                            if (index !== 0) {
                                let name = question.split('_');

                                return (
                                    <MenuItem key={index} value={question}>
                                        {`${name[1]} - ${t(questions?.[0]?.[question])}`}
                                    </MenuItem>
                                );
                            }
                            return null;
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid xs={12} py={2}>
                <Divider />
            </Grid>

            <Grid xs={12}>
                {Object.keys(descriptiveStatistics ?? {})?.map((key) => {
                    if (key === 'frequencies')
                        return (
                            <TableContainer sx={{ maxHeight: '300px', overflowY: 'auto' }}>
                                <Table sx={{ width: '100%' }} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" sx={{ fontWeight: 'bold' }} width={'33%'}>
                                                {t('value')}
                                            </TableCell>
                                            <TableCell align="center" sx={{ fontWeight: 'bold' }} width={'33%'}>
                                                {t('frequency')}
                                            </TableCell>
                                            <TableCell align="center" sx={{ fontWeight: 'bold' }} width={'33%'}>
                                                {t('percent')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {descriptiveStatistics?.['frequencies']?.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="center" width={'33%'}>
                                                    {row.unique_answer__value}
                                                </TableCell>
                                                <TableCell align="center" width={'33%'}>
                                                    {row.count}
                                                </TableCell>
                                                <TableCell align="center" width={'33%'}>
                                                    {`${((row.count / descriptiveStatistics?.answer_count) * 100).toFixed(2)} %`}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        );
                    if (key === 'scale_type')
                        return (
                            <Grid xs={12}>
                                <Grid container justifyContent={'center'}>
                                    <Typography sx={{ fontWeight: 'bold' }}>
                                        {t('scale_type')} : {t(descriptiveStatistics[key])}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    if (key === 'modus') {
                        return (
                            <Grid xs={12}>
                                <Grid container justifyContent={'center'}>
                                    <Typography>
                                        {t('modus') + ': '}
                                        {descriptiveStatistics[key]?.map((item, index) => {
                                            return item?.unique_answer__value.concat(index !== descriptiveStatistics[key]?.length - 1 ? ', ' : '');
                                        })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    }
                    return (
                        <Grid xs={12}>
                            <Grid container justifyContent={'center'}>
                                <Typography>
                                    {t(key)} : {descriptiveStatistics[key]}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                })}
                {descriptiveStatistics?.frequencies?.length && (
                    <Grid xs={12}>
                        <Grid justifyContent={'center'}>
                            {/* <FormControl fullWidth>
                                <InputLabel>{t('chart')}</InputLabel>
                                <Select label={t('chart')} defaultValue={user.preferred_chart} fullWidth>
                                    <MenuItem value={'bar'}>{t('pref_chart_bar')}</MenuItem>
                                    <MenuItem value={'pie'}>{t('pref_chart_pie')}</MenuItem>
                                </Select>
                            </FormControl> */}
                            <Grid xs={12}>
                                <Grid container justifyContent={'space-evenly'} spacing={2}>
                                    {charts.map((chart, idx) => (
                                        <Grid key={idx}>
                                            <img
                                                style={{ cursor: 'pointer' }}
                                                height={40}
                                                src={chart.src}
                                                alt={chart.alt}
                                                onClick={(e) => {
                                                    selectChart(e);
                                                }}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid container justifyContent={'center'}>
                                <SelectPlotType data={descriptiveStatistics} selectedChart={selectedChart} />
                            </Grid>
                            {/* <Grid>
                                <Plot
                                    data={[
                                        {
                                            type: 'bar',
                                            text: descriptiveStatistics?.frequencies?.map((answer, idx) => answer.count),
                                            textposition: 'auto',
                                            x: descriptiveStatistics?.frequencies?.map((answer, idx) => chartLabelShort(answer.unique_answer__value)),
                                            // x: descriptiveStatistics?.frequencies?.map((answer, idx) => answer.unique_answer__value),
                                            y: descriptiveStatistics?.frequencies?.map((answer, idx) => answer.count),
                                            hoverinfo: 'y+text',
                                            hovertext: descriptiveStatistics?.frequencies?.map((answer, idx) => answer.unique_answer__value),
                                            // labelalias:
                                            marker: {
                                                color: colors
                                            }
                                        }
                                    ]}
                                    layout={{
                                        xaxis: {
                                            type: 'category' // Force x-axis values as category (string)
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Plot
                                    data={[
                                        {
                                            type: 'pie',
                                            text: descriptiveStatistics?.frequencies?.map((answer, idx) => answer.count),
                                            labels: descriptiveStatistics?.frequencies?.map((answer, idx) => chartLabelShort(answer.unique_answer__value)),
                                            values: descriptiveStatistics?.frequencies?.map((answer, idx) => answer.count),
                                            hoverinfo: 'y+text',
                                            hovertext: descriptiveStatistics?.frequencies?.map((answer, idx) => answer.unique_answer__value),
                                            marker: {
                                                colors: colors
                                            }
                                        }
                                    ]}
                                />
                            </Grid>{' '} */}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

// <Grid container py={1}>
//                                     <Grid xs={12}>
//                                         CONFIGURATOR
//                                         <FormControl>
//                                             <InputLabel>{`${t('question')}`}</InputLabel>
//                                             <Select
//                                                 value={selectedQuestion}
//                                                 label="Kérdés"
//                                                 onChange={(e) => {
//                                                     setSelectedQuestion(e.target.value);
//                                                     getStatistics(e.target?.value?.name?.split('_')[0]);
//                                                 }}
//                                             >
//                                                 {(surveyDataFetcher?.data?.columns ? surveyDataFetcher?.data?.columns : [])?.map((question, index) => {
//                                                     if (index !== 0)
//                                                         return (
//                                                             <MenuItem key={index} value={question}>
//                                                                 {question?.header}
//                                                             </MenuItem>
//                                                         );
//                                                     return null;
//                                                 })}
//                                             </Select>
//                                         </FormControl>
//                                     </Grid>
//                                     <Grid xs={12} py={2}>
//                                         <Divider />
//                                         <Divider />
//                                         <Divider />
//                                     </Grid>

//                                     <Grid xs={12}>
//                                         RESULTS
//                                         {Object.keys(descriptiveStatistics ?? {})?.map((key) => {
//                                             console.log(key);
//                                             if (key === 'frequencies')
//                                                 return (
//                                                     <Table sx={{ width: '100%' }}>
//                                                         <TableBody>
//                                                             {descriptiveStatistics?.['frequencies']?.map((row, index) => (
//                                                                 <TableRow key={index}>
//                                                                     <TableCell align="center" width={'50%'}>
//                                                                         {row.unique_answer__value}
//                                                                     </TableCell>
//                                                                     <TableCell align="center" width={'50%'}>
//                                                                         {row.count}
//                                                                     </TableCell>
//                                                                 </TableRow>
//                                                             ))}
//                                                         </TableBody>
//                                                     </Table>
//                                                 );
//                                             if (key === 'scale_type')
//                                                 return (
//                                                     <Grid xs={12}>
//                                                         <Grid container justifyContent={'center'}>
//                                                             <Typography variant={'h6'}>
//                                                                 {t('scale_type')} : {t(descriptiveStatistics[key])}
//                                                             </Typography>
//                                                         </Grid>
//                                                     </Grid>
//                                                 );
//                                             if (key === 'modus') {
//                                                 return (
//                                                     <Grid xs={12}>
//                                                         <Grid container justifyContent={'center'}>
//                                                             <Typography variant={'h6'}>
//                                                                 {t('modus') + ': '}
//                                                                 {descriptiveStatistics[key]?.map((item, index) => {
//                                                                     return item?.unique_answer__value.concat(
//                                                                         index !== descriptiveStatistics[key]?.length - 1 ? ', ' : ''
//                                                                     );
//                                                                 })}
//                                                             </Typography>
//                                                         </Grid>
//                                                     </Grid>
//                                                 );
//                                             }
//                                             return (
//                                                 <Grid xs={12}>
//                                                     <Grid container justifyContent={'center'}>
//                                                         <Typography variant={'h6'}>
//                                                             {t(key)} : {descriptiveStatistics[key]}
//                                                         </Typography>
//                                                     </Grid>
//                                                 </Grid>
//                                             );
//                                         })}
//                                     </Grid>
//                                 </Grid>
