import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    components: {
        MuiAutocomplete: {
            defaultProps: {
                fullWidth: true,
            },
        },
        MuiButton: {
            defaultProps: {
                color: 'primary',
                fullWidth: true,
                variant: 'contained',
            },
        },
        MuiDialog: {
            defaultProps: {
                fullWidth: true,
            },
        },
        MuiFormControl: {
            defaultProps: {
                fullWidth: true,
                size: 'small',
            },
        },
        MuiLoadingButton: {
            defaultProps: {
                color: 'primary',
                fullWidth: true,
                type: 'submit',
                variant: 'contained',
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: 8,
            },
        },
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
                inputProps: { maxLength: 255 },
                size: 'small',
            },
        },
        MuiTypography: {
            defaultProps: {
                color: 'black',
            }
        },
    },
    typography: {
        fontFamily: 'Open Sans',
        color: 'white',
    },
    hashLink: {
        fontFamily: 'Open Sans',
    },
});