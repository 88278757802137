import { Grid, FormControl, InputLabel, Select, MenuItem, Button, TextField, Typography, Dialog, DialogContent, Switch, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { axios } from '../utilities/api';
import Plot from 'react-plotly.js';
import HelpIcon from '@mui/icons-material/Help';
import useSnackbar from '../hooks/useSnackbar';
export default function Regression() {
    const { questions, user } = useOutletContext();
    const [infoDialog, setInfoDialog] = useState(false);
    const [selectedX, setSelectedX] = useState('');
    const [selectedY, setSelectedY] = useState('');
    const [regressionType, setRegressionType] = useState('linear');
    const { t } = useTranslation();
    const [regressionData, setRegressionData] = useState({});
    const [polynomialOrder, setPolynomialOrder] = useState(2);
    const [dataLabel, setDataLabel] = useState(false);
    const { setWarning } = useSnackbar();
    async function getStatistics() {
        const data = {
            question1: selectedX?.split('_')[0],
            question2: selectedY?.split('_')[0],
            regression_type: regressionType,
            order: Number(polynomialOrder)
        };
        try {
            const response = await axios.post(`analysis/regression`, data);
            const tooltipLimit = 25;
            const frequencies = response.data?.frequencies;
            if (frequencies.length > tooltipLimit && dataLabel) setDataLabel(false);
            else if (frequencies.length <= tooltipLimit && !dataLabel) setDataLabel(true);
            setRegressionData(response.data);
        } catch (error) {
            console.log(error.response.status);
            if (error?.response?.status === 417) setWarning(`${t('no_number_warning')}: ${error?.response?.data}`);
            // setWarning(t('query_not_successful'));
            throw { message: JSON.stringify(error?.response?.data), status: error?.response?.status ?? 876 };
        }
    }

    function linearRegression() {
        return (
            <Grid container py={1}>
                <Plot
                    data={[
                        {
                            x: [regressionData?.x_min, regressionData?.x_max],
                            y: [regressionData?.x_min, regressionData?.x_max]?.map(
                                (x) => (regressionData?.result?.intercept ?? 0) + (regressionData?.result?.slope ?? 0) * x
                            ),
                            mode: 'lines',
                            type: 'scatter',
                            line: {
                                shape: 'spline',
                                color: 'blue',
                                width: 2
                            },
                            hoverinfo: 'none'
                        },
                        {
                            x: regressionData?.['crosstab_x'],
                            y: regressionData['crosstab_y'],
                            text: regressionData['frequencies'],
                            textposition: 'top center',
                            type: 'scatter',
                            mode: dataLabel ? 'markers+text' : 'markers',
                            marker: { color: 'red' }
                        }
                    ]}
                    layout={{
                        title: t('linear_regression'),
                        xaxis: {
                            title: regressionData?.x_header
                        },
                        yaxis: {
                            title: regressionData?.y_header
                        },
                        height: 500,
                        showlegend: false
                    }}
                />
                {Object.keys(regressionData?.result)?.map((key) => {
                    return (
                        <Grid container justifyContent="center" py={0.5}>
                            <Typography>
                                {key}: {regressionData?.result[key]?.toFixed(4)}
                            </Typography>
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    function polynomialRegression() {
        let distance = (Number(regressionData?.x_max) - Number(regressionData?.x_min)) / 100;
        let x_values = [];
        let y_values = [];
        const max_order = regressionData?.result?.length - 1;
        for (let i = Number(regressionData?.x_min); i <= Number(regressionData?.x_max); i += distance) {
            x_values.push(i);
            let value = 0;

            for (let j = 0; j < regressionData?.result?.length; j++) {
                value += Math.pow(i, max_order - j) * regressionData?.result[j];
            }
            y_values.push(value);
        }
        let terms = [];

        for (let i = 0; i < regressionData?.result?.length; i++) {
            const power = regressionData?.result?.length - 1 - i;
            const coefficient = regressionData?.result?.[i];

            // Ignore zero coefficients
            if (coefficient !== 0) {
                // Determine the term based on the power
                let term, term2;
                if (power === 0) {
                    term = `${coefficient >= 0 ? ' + ' : ' - '} ${Math.abs(coefficient)}`;
                } else if (power === 1) {
                    term = `${coefficient >= 0 ? ' + ' : ' - '} ${Math.abs(coefficient)}x`;
                } else if (i === 0 && coefficient >= 0) {
                    term = `${Math.abs(coefficient)}x`;
                    term2 = <sup>{power}</sup>;
                } else {
                    term = `${coefficient >= 0 ? ' + ' : ' - '} ${Math.abs(coefficient)}x`;
                    term2 = <sup>{power}</sup>;
                }

                // Append the term to the terms array
                terms.push(term, term2);
            }
        }

        return (
            <Grid container py={1}>
                <Plot
                    data={[
                        {
                            x: x_values,
                            y: y_values,
                            mode: 'lines',
                            type: 'scatter',
                            line: {
                                shape: 'spline',
                                color: 'blue',
                                width: 2
                            },
                            hoverinfo: 'none'
                        },
                        {
                            x: regressionData?.['crosstab_x'],
                            y: regressionData['crosstab_y'],
                            text: regressionData['frequencies'],
                            textposition: 'top center',
                            type: 'scatter',
                            mode: dataLabel ? 'markers+text' : 'markers',
                            marker: { color: 'red' }
                        }
                    ]}
                    layout={{
                        title: t('polynomial_regression'),
                        xaxis: {
                            title: regressionData?.x_header
                        },
                        yaxis: {
                            title: regressionData?.y_header
                        },
                        height: 500,
                        showlegend: false
                    }}
                />
                <Grid container py={3}>
                    <Typography>{terms}</Typography>
                </Grid>
            </Grid>
        );
    }

    function logisticRegression() {
        let distance = (Number(regressionData?.x_max) - Number(regressionData?.x_min)) / 100;
        let x_values = [];
        let y_values = [];
        const max_order = regressionData?.result?.length - 1;
        for (let i = Number(regressionData?.x_min); i <= Number(regressionData?.x_max); i += distance) {
            x_values.push(i);
            let value = 1 / (1 + Math.exp(-1 * (regressionData?.result?.coefficient * i + regressionData?.result?.intercept)));

            y_values.push(value);
        }
        let terms = [];

        return (
            <Grid container py={1}>
                <Plot
                    data={[
                        {
                            x: x_values,
                            y: y_values,
                            mode: 'lines',
                            type: 'scatter',
                            line: {
                                shape: 'spline',
                                color: 'blue',
                                width: 2
                            },
                            hoverinfo: 'none'
                        },
                        {
                            x: regressionData?.['crosstab_x'],
                            y: regressionData['crosstab_y'],
                            text: regressionData['frequencies'],
                            textposition: 'top center',
                            type: 'scatter',
                            mode: dataLabel ? 'markers+text' : 'markers',
                            marker: { color: 'red' }
                        }
                    ]}
                    layout={{
                        title: t('logistic_regression'),
                        xaxis: {
                            title: regressionData?.x_header
                        },
                        yaxis: {
                            title: regressionData?.y_header
                        },
                        height: 500,
                        showlegend: false
                    }}
                />
                <Grid container py={3}>
                    <Typography>{`1 / (1 + exp(${-1 * regressionData?.result?.coefficient?.toFixed(4)}x ${regressionData?.result?.intercept < 0 ? '+' : ''}${
                        -1 * regressionData?.result?.intercept?.toFixed(4)
                    }))`}</Typography>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container>
            <Dialog open={infoDialog}>
                <DialogContent>
                    <Grid container justifyContent={'center'}>
                        <Typography align="center">{infoDialog}</Typography>
                    </Grid>
                    <Grid container justifyContent={'center'} pt={2}>
                        <Button sx={{ width: 'auto' }} onClick={() => setInfoDialog(false)}>
                            {t('close')}
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Grid xs={12} py={1}>
                <Typography variant="h5" align="center">
                    {t('regression')}
                    {!user?.details && (
                        <IconButton onClick={() => setInfoDialog(t('regression_help_text'))}>
                            <HelpIcon />
                        </IconButton>
                    )}
                </Typography>
                {user?.details && (
                    <Grid container justifyContent={'center'}>
                        <Typography variant={'body2'} align="center">
                            {t('regression_help_text')}
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Grid xs={12} py={1}>
                <FormControl>
                    <InputLabel>{`${t('independent_variable')}`}</InputLabel>
                    <Select
                        value={selectedX}
                        label={`${t('independent_variable')}`}
                        onChange={(e) => {
                            setSelectedX(e.target.value);
                        }}
                    >
                        {Object.keys(questions?.[0] ?? {})?.map((question, index) => {
                            if (index !== 0) {
                                let name = question.split('_');

                                return (
                                    <MenuItem key={index} value={question}>
                                        {`${name[1]} - ${t(questions?.[0]?.[question])}`}
                                    </MenuItem>
                                );
                            }
                            return null;
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid xs={12} py={1}>
                <FormControl>
                    <InputLabel>{`${t('dependent_variable')}`}</InputLabel>
                    <Select
                        value={selectedY}
                        label={`${t('dependent_variable')}`}
                        onChange={(e) => {
                            setSelectedY(e.target.value);
                        }}
                    >
                        {Object.keys(questions?.[0] ?? {})?.map((question, index) => {
                            if (index !== 0) {
                                let name = question.split('_');

                                return (
                                    <MenuItem key={index} value={question}>
                                        {`${name[1]} - ${t(questions?.[0]?.[question])}`}
                                    </MenuItem>
                                );
                            }
                            return null;
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid xs={12} py={1}>
                <Grid container py={1}>
                    <FormControl fullWidth>
                        <InputLabel>{t('regression_type')}</InputLabel>
                        <Select value={regressionType} label={t('regression_type')} onChange={(e) => setRegressionType(e.target.value)}>
                            <MenuItem value={'linear'}>{t('linear')}</MenuItem>
                            <MenuItem value={'polynomial'}>{t('polynomial')}</MenuItem>
                            <MenuItem value={'logistic'}>{t('logistic')}</MenuItem>
                            {/* <MenuItem value={'logarithmic'}>{t('logarithmic')}</MenuItem>
                            <MenuItem value={'hiperbolic'}>{t('hiperbolic')}</MenuItem>
                            <MenuItem value={'exponential'}>{t('exponential')}</MenuItem> */}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            {regressionType === 'polynomial' && (
                <Grid xs={12} py={1}>
                    <TextField type="number" label={t('polynomial_order')} value={polynomialOrder} onChange={(e) => setPolynomialOrder(e.target.value)} />
                </Grid>
            )}

            <Grid xs={12} py={1}>
                <Button disabled={!selectedX?.length || !selectedY?.length} onClick={() => getStatistics()}>
                    {t('start_query')}
                </Button>
            </Grid>
            {regressionData?.crosstab_x?.length ? (
                <Grid container justifyContent={'center'}>
                    <Typography align="center">
                        {t('data_label')}
                        <Switch checked={dataLabel} onChange={() => setDataLabel((prev) => !prev)} />
                    </Typography>
                </Grid>
            ) : null}
            {regressionData?.type === 'linear' && linearRegression()}
            {regressionData?.type === 'polynomial' && polynomialRegression()}
            {regressionData?.type === 'logistic' && logisticRegression()}
        </Grid>
    );
}
